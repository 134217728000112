import React, { useState, useEffect } from "react";
import { StyleSheet, View, Text, Pressable, Modal } from "react-native";
import axios from "axios";
import { useUser } from "../../Widgets/UserContext";
import { getFirestore, doc, getDoc, updateDoc } from "firebase/firestore"; // Ensure you import these
import { onAuthStateChanged, signInWithEmailAndPassword } from "firebase/auth"; // Ensure correct imports
import { app, auth } from "../../firebase"; // Assuming you have these firebase configurations
import { REACT_APP_AWS_SERVER_URL } from '@env';	

let LOCAL_SERVER_URL = "http://localhost:3300";
let AWS_SERVER_URL = REACT_APP_AWS_SERVER_URL;

const isDevelopment = process.env.NODE_ENV === "development"; // Adjust this condition based on your setup
const SERVER_URL = isDevelopment ? LOCAL_SERVER_URL : AWS_SERVER_URL;


function ConnectedAccount(pageOwner) {
    const db = getFirestore(app);
    const email = pageOwner.email;
    const [accountCreated, setAccountCreated] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const { currentUser, currentUserData, setCurrentUser, setCurrentUserData } = useUser();

    const handleCreateAccount = async () => {
        setIsLoading(true);
        try {
            const returnUrl = pageOwner.pages.registry || pageOwner.pages.events;
            alert(`Attempting to use return URL: ${returnUrl}`); // Alert the URL being used

            if (!returnUrl) {
                throw new Error("No valid return URL found.");
            }

            const response = await axios.post(`${SERVER_URL}/create-connected-account`, {
                email: email,
                refresh_url: returnUrl,
                return_url: returnUrl,
                pageOwner: pageOwner,
                capabilities: {
                    transfers: { requested: true },
                    crypto_transfers: { requested: true },
                    legacy_payments: { requested: true }
                }
            });

            // console.log(response.data);
            // Redirect to Stripe's onboarding form
            if (response.data.accountLink && response.data.accountLink.url) {
                window.location = response.data.accountLink.url;
            } else {
                // Adjusted if response.data directly contains the URL
                window.location = response.data.url;
            }
        } catch (error) {
            console.error("Failed to create connected account:", error);
            alert(`Failed to create connected account: ${error.message}`);
        } finally {
            setIsLoading(false);
        }
    };
};

const ConnectedAccountModal = ({ visible, onClose, pageOwner }) => {
    const [email, setEmail] = useState(pageOwner.email); // Initialize email state with pageOwner.email
    const [accountCreated, setAccountCreated] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [hasStripeAccount, setHasStripeAccount] = useState(false);

    useEffect(() => {
        // Update email state if pageOwner.email changes
        setEmail(pageOwner.email);
        setHasStripeAccount(pageOwner?.stripeAccountId);
    }, [pageOwner.email]);

    useEffect(() => {
        // Assuming pageOwner is an object that might include stripeAccountId
        // Directly check if pageOwner has a stripeAccountId and update state accordingly
        if (pageOwner && pageOwner.stripeAccountId) {
            setHasStripeAccount(true);
        } else {
            setHasStripeAccount(false);
        }
    }, [pageOwner]); // Depend on pageOwner to re-run this effect

    const handleCreateAccount = async () => {
        setIsLoading(true);
        try {
            const returnUrl = pageOwner.pages.registry || pageOwner.pages.events;
            alert(`Attempting to use return URL: ${returnUrl}`); // Alert the URL being used

            if (!returnUrl) {
                throw new Error("No valid return URL found.");
            }

            const response = await axios.post(`${SERVER_URL}/create-connected-account`, {
                email: email,
                refresh_url: returnUrl,
                return_url: returnUrl,
                pageOwner: pageOwner,
                capabilities: {
                    transfers: { requested: true },
                    crypto_transfers: { requested: true },
                    legacy_payments: { requested: true }
                }
            });

            // console.log(response.data);
            // Redirect to Stripe's onboarding form
            if (response.data.url && response.data.stripeAccountId) {
                // Save stripeAccountId to Firestore
                const db = getFirestore(app);
                if (email) { // Ensure email is not empty
                    const userRef = doc(db, "users", email); // Correctly reference the document
                    await updateDoc(userRef, {
                        stripeAccountId: response.data.stripeAccountId
                    });
                    window.location = response.data.url;
                } else {
                    console.error("Email is undefined or empty.");
                    alert("An error occurred. Please try again.");
                }
            } else {
                alert("Failed to get the account link URL. Please try again.");
            }
        } catch (error) {
            console.error("Failed to create connected account:", error);
            alert(`Failed to create connected account: ${error.message}`);
        } finally {
            setIsLoading(false);
        }
    };

    if (!visible) {
        return null;
    }

    return (
        <Modal visible={visible} transparent={true} onRequestClose={onClose}>
            <View style={styles.blurredContainer}>
                <View style={styles.modalContent}>
                    <Text style={styles.title}>Create Your Stripe Connected Account</Text>
                    {!hasStripeAccount && (
                        <View>
                            <Pressable onPress={handleCreateAccount} disabled={isLoading} style={styles.button}>
                                {isLoading ? 
                                <Text style={styles.buttonText}>Creating...</Text> : 
                                <Text style={styles.buttonText}>Create Account</Text>}
                            </Pressable>
                        </View>
                    )}
                    {hasStripeAccount && (
                        <Text>Your Stripe connected account has been successfully created!</Text>
                    )}
                    <Pressable onPress={onClose} style={styles.closeButton}>
                        <Text style={styles.buttonText}>Close</Text>
                    </Pressable>
                </View>
            </View>
        </Modal>
    );
};


const styles = StyleSheet.create({
    blurredContainer: {
        position: "absolute",
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        backdropFilter: "blur(10px)", // Apply blur effect
        justifyContent: "center",
        alignItems: "center",
    },
    modalContent: {
        padding: 20,
        backgroundColor: "#fefde2",
        borderRadius: 10,
        shadowColor: "#000",
        shadowOffset: { width: 0, height: 2 },
        shadowOpacity: 0.25,
        shadowRadius: 3.84,
        elevation: 5,
        margin: 20, // Ensures modal doesn't stretch to the edges of the screen
        alignItems: "center", // Centers the modal's content
    },
    title: {
        fontSize: 24,
        fontWeight: "bold",
        marginBottom: 20,
    },
    button: {
        backgroundColor: "#4c956c",
        paddingVertical: 10,
        paddingHorizontal: 20,
        borderRadius: 20,
        marginTop: 10,
        width: 200, // Adjusts button width to match modal's width
        alignItems: "center", // Ensures text is centered within the button
    },
    buttonText: {
        color: "#fff",
        fontSize: 18,
    },
    closeButton: {
        backgroundColor: "red",
        paddingVertical: 10,
        paddingHorizontal: 20,
        borderRadius: 20,
        marginTop: 10,
        width: 200, // Adjusts button width to match modal's width
        alignItems: "center", // Ensures text is centered within the button
    },
});

export { ConnectedAccount, ConnectedAccountModal };