// Checkout.js
import React, { useState, Alert } from "react";
import { Modal, View, Text, TextInput, Pressable, Image, StyleSheet } from "react-native";
import { useUser } from "../../Widgets/UserContext";
import firebase from "firebase/app";
import 'firebase/storage'
import { CardElement, useStripe, useElements } from "@stripe/react-stripe-js"
import axios from "axios";
import { REACT_APP_AWS_SERVER_URL } from '@env';	



const Checkout = ({ isVisible, onClose, selectedECard, pageOwner }) => {
	const [amount, setAmount] = useState("");
	const processingFee = 5.0;
	const recipientEmail = pageOwner.email;
	const recipientName = pageOwner.firstName;
	const [guestEmail, setGuestEmail] = useState("");
	const [guestName, setGuestName] = useState("");

	const stripe = useStripe();
	const elements = useElements();

	let LOCAL_SERVER_URL = "http://localhost:3300";
	let AWS_SERVER_URL = REACT_APP_AWS_SERVER_URL;

	const isDevelopment = process.env.NODE_ENV === "development"; // Adjust this condition based on your setup
	const SERVER_URL = isDevelopment ? LOCAL_SERVER_URL : AWS_SERVER_URL;
	 console.log("I see you. Do you see me?", process.env.NODE_ENV==="development");

	const { currentUser, currentUserData } = useUser();

	// console.log("Recipient:", recipientEmail);
	// console.log("Sender:", currentUserData?.email || guestEmail);
	// console.log("Card:", selectedECard);

	// Add this function to format the amount display
	const getFormattedAmount = () => {
		const numAmount = parseFloat(amount);
		return !isNaN(numAmount) ? `$${numAmount.toFixed(2)}` : "$0.00";
	};

	const [showThankYouModal, setShowThankYouModal] = useState(false);

	// Stripe Elements styling
	const stripeElementsStyle = {
		style: {
			base: {
				fontSize: '16px',
				color: '#424770',
				fontFamily: 'Orpheus Pro, sans-serif',
				'::placeholder': {
					color: '#aab7c4',
				},
				backgroundColor: '#fff',
				padding: '10px',
			},
			invalid: {
				color: '#9e2146',
			},
		},
	};

	const handleSubmit = async () => {
		const contributionAmount = parseFloat(amount) || 0;
		if (contributionAmount > 0) {
			try {
				// Get card element
				const cardElement = elements.getElement(CardElement);
				if (!cardElement) {
					throw new Error('Card element not found');
				}

				// Create payment method
				const { error: createError, paymentMethod } = await stripe.createPaymentMethod({
					type: 'card',
					card: cardElement,
				});

				if (createError) {
					throw new Error(createError.message);
				}

				// Process the payment with the created payment method
				const { clientSecret } = await processPayment(contributionAmount + processingFee);
				
				// Confirm the payment
				const { error: confirmError, paymentIntent } = await stripe.confirmCardPayment(
					clientSecret,
					{
						payment_method: paymentMethod.id,
					}
				);

				if (confirmError) {
					throw new Error(confirmError.message);
				}

				if (paymentIntent.status === 'succeeded') {
					// Send the eCard after successful payment
					await sendECard({
						frontImageUrl: selectedECard?.frontImageUrl,
						backImageUrl: selectedECard?.backImageUrl,
						contributionAmount: amount,
						recipientEmail: recipientEmail,
						senderEmail: currentUserData?.email || guestEmail,
						senderName: currentUserData?.firstName || guestName,
						name: recipientName
					});
					setShowThankYouModal(true); // Show thank you modal instead of alert
				}
			} catch (error) {
				console.error("Error during payment:", error);
				alert("Error", error.message || "There was a problem processing your payment. Please try again.");
			}
		} else {
			alert("Invalid Amount", "Please enter a valid contribution amount greater than $0.");
		}
	};

	// Update processPayment to return the clientSecret
	const processPayment = async (totalAmount) => {
		try {
			const response = await axios.post(`${SERVER_URL}/create-payment-intent`, {
				amount: Math.round(totalAmount * 100), // Convert to cents and ensure it's an integer
				currency: "usd",
				sellerAccountId: pageOwner.stripeAccountId,
			});

			return { clientSecret: response.data.clientSecret };
		} catch (error) {
			throw new Error("Failed to process payment: " + error.message);
		}
	};

	const sendECard = async (eCardData) => {
		try {
			const response = await fetch(`${SERVER_URL}/send-ecard-email`, {
					method: "POST",
					headers: {
						"Content-Type": "application/json",
					},
					body: JSON.stringify(eCardData),
				});

			if (!response.ok) throw new Error("Failed to send eCard");
		} catch (error) {
			console.error("Error sending eCard:", error);
			throw error; // Rethrow to handle in the calling function
		}
	};

	const ThankYouModal = () => (
		<Modal visible={showThankYouModal} animationType="fade" transparent={true}>
			<View style={styles.centeredView}>
				<View style={styles.thankYouModalView}>
					<Image 
						source={require('../../assets/logo.png')} 
						style={styles.thankYouLogo}
					/>
					<Text style={styles.thankYouTitle}>Thank You!</Text>
					<Text style={styles.thankYouText}>
						Your contribution of {getFormattedAmount()} has been successfully processed.
						An eCard confirmation has been sent to your email.
					</Text>
					<Pressable
						style={styles.thankYouButton}
						onPress={() => {
							setShowThankYouModal(false);
							onClose();
						}}
					>
						<Text style={styles.thankYouButtonText}>Close</Text>
					</Pressable>
				</View>
			</View>
		</Modal>
	);

	// Add guest information form if user is not logged in
	const GuestInfoForm = () => {
		if (currentUserData) return null;

		return (
			<View style={styles.guestInfoContainer}>
				<Text style={styles.cardInputLabel}>Guest Information</Text>
				<TextInput
					style={styles.input}
					placeholder="Your Name"
					value={guestName}
					onChangeText={setGuestName}
				/>
				<TextInput
					style={styles.input}
					placeholder="Your Email"
					value={guestEmail}
					onChangeText={setGuestEmail}
					keyboardType="email-address"
				/>
			</View>
		);
	};

	return (
		<Modal visible={isVisible} animationType="slide" transparent={true}>
			<View style={styles.centeredView}>
				<View style={styles.modalView}>
					{selectedECard &&
						<View style={styles.eCardContainer}>
							<Image source={{ uri: selectedECard.frontImageUrl }} style={styles.eCardPreview} />
							<Image source={{ uri: selectedECard.backImageUrl }} style={styles.eCardPreview} />
						</View>
					}
					<GuestInfoForm />
					<TextInput 
						style={styles.input} 
						placeholder="Contribution Amount" 
						value={amount} 
						onChangeText={setAmount} 
						keyboardType="numeric" 
					/>
					<View style={styles.summary}>
						<Text style={styles.summaryText}>Contribution: {getFormattedAmount()}</Text>
						<Text style={styles.summaryText}>Processing Fee: $5.00</Text>
						<Text style={styles.summaryTextTotal}>
							Total: ${((parseFloat(amount) || 0) + processingFee).toFixed(2)}
						</Text>
					</View>
					<View style={styles.cardInputContainer}>
						<Text style={styles.cardInputLabel}>Card Details</Text>
						<CardElement options={stripeElementsStyle} />
					</View>
					<Pressable style={styles.finishButton} onPress={handleSubmit}>
						<Text style={styles.finishButtonText}>Complete Contribution</Text>
					</Pressable>
					<Pressable style={styles.closeButton} onPress={onClose}>
						<Text style={styles.buttonText}>Cancel</Text>
					</Pressable>
				</View>
			</View>
			<ThankYouModal />
		</Modal>
	);
};

const styles = StyleSheet.create({
	centeredView: {
		flex: 1,
		justifyContent: "center",
		alignItems: "center",
		marginTop: 22,
	},
	modalView: {
		margin: 20,
		backgroundColor: "#fefde2",
		borderRadius: 20,
		padding: 35,
		alignItems: "center",
		shadowColor: "#000",
		shadowOffset: {
			width: 0,
			height: 2,
		},
		shadowOpacity: 0.25,
		shadowRadius: 4,
		elevation: 5,
		width: "90%", // Adjusted width to make it wider
	},
	eCardContainer: {
		flexDirection: 'row',
		justifyContent: 'space-between',
	},
	eCardPreview: {
		width: 300,
		height: 400,
		marginBottom: 15,
	},
	input: {
		borderWidth: 1,
		borderColor: "#ddd",
		borderRadius: 10,
		padding: 15,
		marginBottom: 20,
		width: "100%",
		backgroundColor: '#fff',
		fontSize: 16,
		color: '#424770',
	},
	summary: {
		marginBottom: 15,
		width: "100%", // Ensure summary takes full width
	},
	summaryText: {
		fontSize: 16,
		lineHeight: 24,
	},
	cardInputContainer: {
		width: "100%",
		marginBottom: 20,
		backgroundColor: '#fff',
		borderRadius: 10,
		padding: 15,
		shadowColor: "#000",
		shadowOffset: {
			width: 0,
			height: 2,
		},
		shadowOpacity: 0.1,
		shadowRadius: 3,
		elevation: 3,
	},
	cardInputLabel: {
		fontSize: 16,
		color: '#424770',
		marginBottom: 10,
		fontFamily: 'Orpheus Pro',
	},
	summaryTextTotal: {
		fontSize: 18,
		fontWeight: 'bold',
		color: '#4c956c',
		marginTop: 5,
	},
	thankYouModalView: {
		backgroundColor: '#fefde2',
		borderRadius: 20,
		padding: 35,
		alignItems: 'center',
		shadowColor: '#000',
		shadowOffset: {
			width: 0,
			height: 2,
		},
		shadowOpacity: 0.25,
		shadowRadius: 4,
		elevation: 5,
		width: '90%',
		maxWidth: 500,
	},
	thankYouLogo: {
		width: 100,
		height: 100,
		marginBottom: 20,
	},
	thankYouTitle: {
		fontSize: 24,
		fontWeight: 'bold',
		color: '#4c956c',
		marginBottom: 15,
		fontFamily: 'Orpheus Pro',
	},
	thankYouText: {
		fontSize: 16,
		textAlign: 'center',
		marginBottom: 20,
		lineHeight: 24,
		color: '#424770',
		fontFamily: 'Orpheus Pro',
	},
	thankYouButton: {
		backgroundColor: '#4c956c',
		borderRadius: 20,
		padding: 15,
		elevation: 2,
		width: '100%',
		maxWidth: 200,
	},
	thankYouButtonText: {
		color: 'white',
		fontWeight: 'bold',
		textAlign: 'center',
		fontSize: 16,
		fontFamily: 'Orpheus Pro',
	},
	finishButton: {
		borderRadius: 20,
		padding: 15,
		elevation: 2,
		backgroundColor: "#4c956c",
		width: "100%",
		alignItems: "center",
		marginBottom: 10,
	},
	finishButtonText: {
		color: "white",
		fontWeight: "bold",
		textAlign: "center",
	},
	closeButton: {
		borderRadius: 20,
		padding: 15,
		elevation: 2,
		backgroundColor: "#cc0000",
		width: "100%",
		alignItems: "center",
	},
	buttonText: {
		color: "white",
		fontWeight: "bold",
		textAlign: "center",
	},
	guestInfoContainer: {
		width: "100%",
		marginBottom: 20,
		backgroundColor: '#fefde2',
		borderRadius: 10,
		padding: 15,
	},
});

export default Checkout;