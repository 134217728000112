import { StyleSheet, Platform, Dimensions } from "react-native";

const { width, height } = Dimensions.get('window');
const isMobile = width < 768;
const isTablet = width >= 768 && width < 1024;
const isSmallDesktop = width >= 1024 && width < 1440;
const isLargeDesktop = width >= 1440;

const fontFamilies = {
	Bodoni72Smallcaps: "Bodoni72Smallcaps",
};

const maxViewHeight = "500vh";

const styles = StyleSheet.create({
	container: {
		flex: 1,
		width: "100vw",
		height: maxViewHeight,
		position: "relative", // This allows absolute positioning of its children
		paddingBottom: 400,
	},
	body: {
		flex: 1,
		height: "auto",
	},
	section: {
		flex: 1, // Each section takes up an equal portion of the screen's height
		minHeight: "100vh",
		zIndex: 1,
		left: 0,
		right: 0,
		justifyContent: "center",
		alignContent: "center",
		paddingBottom: 125,
		top: 0,
	},
	headerBar: {
		flex: 1,
		flexDirection: "row",
		justifyContent: "space-between",
		backgroundColor: "transparent",
		paddingHorizontal: 100,
		paddingTop: 50,
		position: "relative",
		minWidth: "100vw",
		zIndex: 100010,
	},
	logoArea: {
		flex: 1,
		flexDirection: "row",
		justifyContent: "space-between",
		backgroundColor: "transparent",
		height: 62,
		marginTop: 10,
	},
	logoText: {
		fontFamily: fontFamilies.Bodoni72Smallcaps,
		fontWeight: "normal",
		fontSize: 50,
		textAlign: "left",
		color: "#fefde2",
		textShadowOffset: { width: 3, height: 3 },
		textShadowRadius: 6,
		textShadowColor: "rgba(0, 0, 0, 0.09)",
	},
	loginArea: {
		flex: 1,
		flexDirection: "row",
		backgroundColor: "transparent",
		maxHeight: 50,
	},
	loginButton: {
		width: 208,
		height: 62,
		backgroundColor: "transparent",
		alignItems: "center",
		justifyContent: "center",
		boxShadowOffset: { width: 3, height: 3 },
		boxShadowRadius: 6,
		boxShadowColor: "rgba(0, 0, 0, 0.16)",
		zIndex: 99,
		marginLeft: isMobile ? 20 : isTablet ? 50 : isSmallDesktop ? 100 : 150,
	},
	backButton: {
		position: "absolute",
		right: isMobile ? 20 : isTablet ? 50 : isSmallDesktop ? 100 : 150,
		width: "auto",
		height: 62,
		backgroundColor: "transparent",
		shadowOffset: { width: 7, height: 3 },
		shadowRadius: 6,
		shadowColor: "rgba(0, 0, 0, 0.04)",
		alignItems: "center",
		justifyContent: "center",
		zIndex: 99,
	},
	backButtonText: {
		fontFamily: "Orpheus Pro",
		fontSize: 25,
		lineHeight: 30,
		textAlign: "center",
		color: "#e4e4e4",
		textShadowOffset: { width: 3, height: 3 },
		textShadowRadius: 6,
		textShadowColor: "rgba(0, 0, 0, 0.09)",
	},
	loginButtonText: {
		fontFamily: "Orpheus Pro",
		fontSize: 25,
		lineHeight: 30,
		textAlign: "center",
		color: "#e4e4e4",
		textShadowOffset: { width: 3, height: 3 },
		textShadowRadius: 6,
		textShadowColor: "rgba(0, 0, 0, 0.09)",
	},
	cancelButtonText: {
		fontFamily: "Orpheus Pro",
		fontSize: 25,
		textAlign: "center",
		color: "#e4e4e4",
		textShadowOffset: { width: 3, height: 3 },
		textShadowRadius: 6,
		textShadowColor: "rgba(0, 0, 0, 0.09)",
		marginHorizontal: 25,
		paddingRight: 90,
	},
	signOutButtonText: {
		fontFamily: "Orpheus Pro",
		fontSize: 25,
		textAlign: "center",
		color: "red",
		textShadowOffset: { width: 3, height: 3 },
		textShadowRadius: 6,
		textShadowColor: "rgba(0, 0, 0, 0.09)",
	},
	buttonText: {
		fontFamily: "Orpheus Pro",
		fontWeight: "500",
		fontSize: 28,
		lineHeight: 30,
		textAlign: "center",
		color: "#fefde2",
		textShadowOffset: { width: 3, height: 3 },
		textShadowRadius: 6,
		textShadowColor: "rgba(0, 0, 0, 0.09)",
	},
	backgroundImage: {
		position: "absolute",
		width: "100%",
		resizeMode: "cover",
	},
	welcomeText: {
		width: 1000,
		height: 127,
		fontFamily: fontFamilies.Bodoni72Smallcaps,
		fontSize: 90,
		textAlign: "center",
		color: "#fefde2",
		textShadowOffset: { width: 30, height: 3 },
		textShadowRadius: 4,
		textShadowColor: "rgba(0, 0, 0, 0.06)",
		textAlign: "center", // Change this for horizontal centering
		alignSelf: "center", // Add this for vertical centering
	},
	descriptionText: {
		width: 731,
		height: 199,
		fontFamily: "Orpheus Pro",
		fontWeight: "500",
		fontSize: 45,
		lineHeight: 60,
		textAlign: "center",
		color: "#fefde2",
		textShadowOffset: { width: 3, height: 3 },
		textShadowRadius: 6,
		textShadowColor: "rgba(0, 0, 0, 0.16)",
		textAlign: "center", // Change this for horizontal centering
		alignSelf: "center", // Add this for vertical centering
	},
	pageHeader: {
		flex: 1,
		flexDirection: "row",
		alignItems: "center",
		zIndex: 10,
		paddingVertical: isMobile ? 5 : isTablet ? 25 : isSmallDesktop ? 25 : 50,
		marginTop: isMobile ? -100 : isTablet ? -100 : isSmallDesktop ? -100 : -100,

	},
	header: {
		fontFamily: fontFamilies.Bodoni72Smallcaps,
		color: "#e4e4e4",
		fontSize: 45,
		textShadowOffset: { width: 3, height: 3 },
		textShadowRadius: 6,
		textShadowColor: "rgba(0, 0, 0, 0.4)",
		paddingBottom: 25,
		marginTop: 100,
	},
	graduationCardContainer: {
		height: isMobile ? Dimensions.get("window").height - 150 : Dimensions.get("window").height - 200,
		width: isMobile ? Dimensions.get("window").width - 50 : Dimensions.get("window").width - 100, // Set width to 1/4th of screen width
		marginTop: isMobile ? 300 : isTablet ? 300 : isSmallDesktop ? 325 : 325,
	},
	yearbookContainer: {
		flex: 1,
		alignItems: "center",
		justifyContent: "center",
		width: "30vw",
		minHeight: "100%",
		left: isMobile ? 5 : isTablet ? 50 : isSmallDesktop ? 30 : 0,
		top: isMobile ? 100 : isTablet ? 100 : isSmallDesktop ? 800 : 850,

	},
	goalTrackerContainer: {
		flex: 1, // takes up 1/3 of the space
		alignItems: "center",
		justifyContent: "center",
		width: "30vw",
		minHeight: "100%",
		right: isMobile ? 5 : isTablet ? 50 : isSmallDesktop ? 30 : 0,
		top: isMobile ? 100 : isTablet ? 100 : isSmallDesktop ? 800 : 850,

	},
	eventsButton: {
		backgroundColor: "#fefde2",
		width: 284,
		height: 55,
		alignItems: "center",
		justifyContent: "center",
		borderRadius: 40,
		transition: 'all 1s ease',
		marginTop: 25,
	},
	eventsButtonHover: {
		backgroundColor: "#e6e5cb",
		transform: [{ scale: 1.025 }],
	},
	eventsButtonText: {
		fontFamily: "Orpheus Pro",
		fontWeight: "500",
		fontSize: 20,
		lineHeight: 30,
		textAlign: "center",
		color: "#4c956c",
		textShadowOffset: { width: 3, height: 3 },
		textShadowRadius: 6,
		textShadowColor: "rgba(0, 0, 0, 0.09)",
	},
	profileContainer: {
		flex: 1, // takes up 1/3 of the space
		alignItems: "center",
		justifyContent: "center",
		position: "relative",
		top: 0,
		right: 0,
		left: 0,
		marginTop: -200,
		marginBottom: 100,
		borderRadius: 250,
		zIndex: 1000,
		paddingHorizontal: 100,

	},
	profileImage: {
		width: 250,
		height: 250,
		borderRadius: 250,
		marginBottom: 10,
	},
	sealImage: {
		width: 75,
		height: 75,
		borderRadius: 60,
		marginTop: 25,
		marginRight: 175,
		position: "absolute",
	},
	welcomeText: {
		fontFamily: fontFamilies.Bodoni72Smallcaps,
		color: "#e4e4e4",
		fontSize: 90,
		paddingBottom: 25,
		paddingTop: 50,
		textShadowOffset: { width: 3, height: 3 },
		textShadowRadius: 6,
		textShadowColor: "rgba(0, 0, 0, 0.4)",
	},
	profileName: {
		fontFamily: fontFamilies.Bodoni72Smallcaps,
		color: "#e4e4e4",
		fontSize: 75,
		textShadowOffset: { width: 3, height: 3 },
		textShadowRadius: 6,
		textShadowColor: "rgba(0, 0, 0, 0.4)",
	},
	profileDetails: {
		fontFamily: fontFamilies.Bodoni72Smallcaps,
		color: "#e4e4e4",
		fontSize: 25,
		textShadowOffset: { width: 3, height: 3 },
		textShadowRadius: 6,
		textShadowColor: "rgba(0, 0, 0, 0.4)",
	},
	profileDetailsSmall: {
		fontFamily: fontFamilies.Bodoni72Smallcaps,
		paddingTop: 2,
		color: "#e4e4e4",
		fontSize: 20,
		textShadowOffset: { width: 3, height: 3 },
		textShadowRadius: 6,
		textShadowColor: "rgba(0, 0, 0, 0.4)",
	},
	buttonsContainer: {
		flexDirection: "row",
		justifyContent: "center",
		maxWidth: 1500,
		alignSelf: "center", // Add this for vertical centering
		marginTop: 50,
	},
	buttonArea: {
		marginHorizontal: 50,
		flexDirection: "column",
		justifyContent: "center",
		alignContent: "center",
		alignSelf: "center", // Add this for vertical centering
	},
	button: {
		width: 284,
		height: 55,
		backgroundColor: "#4c956c",
		alignItems: "center",
		justifyContent: "center",
		borderRadius: 40,
		shadowColor: "#000",
		shadowOffset: { width: 0, height: 2 },
		shadowOpacity: 0.25,
		shadowRadius: 3.84,
		elevation: 5,
	},
	buttonDescription: {
		width: 300,
		height: 96,
		paddingTop: 15,
		fontFamily: "Orpheus Pro",
		fontWeight: "bold",
		fontSize: 18,
		lineHeight: 30,
		textAlign: "center",
		color: "#fefde1",
		textShadowOffset: { width: 3, height: 3 },
		textShadowRadius: 6,
		textShadowColor: "rgba(0, 0, 0, 0.16)",
		alignSelf: "center", // Add this for vertical centering
	},
	// editButtonContainer: {
	// 	zIndex: 1000, //Will overlap catalogue
	// },
	editButton: {
		alignSelf: "flex-end",
		marginRight: "5%",
		marginTop: 20,
		paddingVertical: 10,
		paddingHorizontal: 10,
		borderRadius: 20,
		backgroundColor: "#fefde2",
		shadowOffset: { width: 0, height: 2 },
		shadowOpacity: 0.25,
		shadowRadius: 3.84,
		elevation: 5,
		width: "20%",
		top: 100,
		position: Platform.OS === "web" ? "fixed" : "absolute",
		zIndex: 99,
		transition: 'all 1s ease',
	},
	editButtonHover: {
		backgroundColor: "#e6e5cb",
		transform: [{ scale: 1.025 }],
	},
	backgroundButton: {
		alignSelf: "flex-end", // Aligns the button to the right
		marginRight: "5%", // Adds spacing from the right edge of the screen
		marginTop: 20, // Adds spacing from the top
		paddingVertical: 10,
		paddingHorizontal: 20,
		borderRadius: 20,
		backgroundColor: "#4c956c", // A green background color
		shadowOffset: { width: 0, height: 2 },
		shadowOpacity: 0.25,
		shadowRadius: 3.84,
		elevation: 5,
		width: "20%",
		top: 160,
		position: Platform.OS === "web" ? "fixed" : "absolute",
		zIndex: 99,
	},
	editButtonText: {
		fontFamily: "Orpheus Pro",
		fontSize: isMobile ? 14 : isTablet ? 20 : isSmallDesktop ? 22 : 25,
		textAlign: "center",
		color: "#e4e4e4",
		textShadowOffset: { width: 3, height: 3 },
		textShadowRadius: 6,
		textShadowColor: "rgba(0, 0, 0, 0.09)",
		marginHorizontal: 25,
	},
	viewButton: {
		alignSelf: "flex-end",
		marginRight: "26%",
		marginTop: 20,
		paddingVertical: 10,
		paddingHorizontal: 10,
		borderRadius: 20,
		backgroundColor: "#4c956c",
		shadowOffset: { width: 0, height: 2 },
		shadowOpacity: 0.25,
		shadowRadius: 3.84,
		elevation: 5,
		width: "20%",
		top: 100,
		position: Platform.OS === "web" ? "fixed" : "absolute",
		zIndex: 1000,
	},
	viewButtonHover: {
		backgroundColor: "#e6e5cb",
		transform: [{ scale: 1.025 }],
	},
	viewButtonText: {
		fontFamily: "Orpheus Pro",
		fontSize: isMobile ? 14 : isTablet ? 20 : isSmallDesktop ? 22 : 25,
		textAlign: "center",
		color: "#e4e4e4",
		textShadowOffset: { width: 3, height: 3 },
		textShadowRadius: 6,
		textShadowColor: "rgba(0, 0, 0, 0.09)",
		marginHorizontal: 25,
		zIndex: 1000,
	},

	warningText: {
		color: '#333',
		fontSize: 14,
	},
	editControls: {
		position: "absolute", // Position absolutely to place over other content
		bottom: 0, // Align to the bottom of the parent
		left: 0, // Align to the left of the parent
		right: 0, // Align to the right of the parent
		height: 40,
		backgroundColor: "rgba(0,0,0,0.5)", // Semi-transparent background
		padding: 20, // Add some padding around the buttons
		flexDirection: "row", // Layout buttons in a row
		justifyContent: "center", // Center buttons horizontally
		alignItems: "center", // Center items vertically within the container
		justifyContent: "space-around",
		// @ts-ignore
		position: Platform.OS === "web" ? "fixed" : undefined,
	},
	widgetMenu: {
		position: "absolute",
		height: "100%",
		bottom: 0,
		left: 0,
		right: 0,
		backgroundColor: "#4c956c",
		paddingVertical: 50,
		paddingHorizontal: 20,
		paddingBottom: 20,
		flexDirection: "row",
		justifyContent: "space-around",
		// @ts-ignore

		position: Platform.OS === "web" ? "fixed" : undefined,
	},
	widgetMenuScrollView: {
		position: "absolute",
		height: "20%",
		bottom: 0,
		left: 0,
		right: 0,
		backgroundColor: "#4c956c",
		paddingVertical: 10,
		paddingHorizontal: 20,
		paddingBottom: 20,
		// @ts-ignore
		position: Platform.OS === "web" ? "fixed" : undefined,
	},
	widgetButton: {
		padding: 10,
		backgroundColor: "rgba(255, 255, 255, 0)",
		borderRadius: 25,
		height: "75%",
		width: Dimensions.get("window").width / 4, // Set width to 1/4th of screen width
		alignItems: "center",
		justifyContent: "center",
		padding: 10,
		marginHorizontal: 5,
		justifyContent: "center", // Center children vertically
		alignItems: "center", // Center children horizontally
	},
	widgetSpacer: {
		paddingRight: 450,
	},
	previewStyle: {
		width: isMobile ? "100vw" : 500, // adjust size accordingly
		height: isMobile ? "100vw" : 500, // adjust size accordingly
	},
	prevText: {
		fontFamily: fontFamilies.Bodoni72Smallcaps,
		fontSize: 18,
		textAlign: "center",
		color: "#fefde2",
		textShadowOffset: { width: 30, height: 3 },
		textShadowRadius: 4,
		textShadowColor: "rgba(0, 0, 0, 0.06)",
		textAlign: "center", // Change this for horizontal centering
		alignSelf: "center", // Add this for vertical centering
	},
	addGridButton: {
		marginTop: 500, // Space above the button
		width: "100%", // Full width to center the button text
		alignItems: "center", // Center content horizontally in the container
	},
	savePopup: {
		position: "absolute",
		bottom: 20, // You can adjust this
		left: 0,
		right: 0,
		alignItems: "center",
	},
	savePopupText: {
		backgroundColor: "#4c956c", // Or any color you prefer
		color: "white",
		padding: 10,
		borderRadius: 5,
	},
	errorPopup: {
		position: "absolute",
		bottom: 20, // You can adjust this
		left: 0,
		right: 0,
		alignItems: "center",
		backgroundColor: "red", // Or any color you prefer
	},
	errorPopupText: {
		color: "white",
		padding: 10,
		borderRadius: 5,
	},
	// Styles for the widget placeholders within the EditablePage component
	widgetPlaceholder: {
		borderWidth: 2,
		borderColor: "#cccccc", // A light grey border color
		borderRadius: 10,
		padding: 20,
		marginBottom: 20, // Adds spacing between each widget placeholder
		alignItems: "center",
		justifyContent: "center",
	},
	// Text style for empty widget placeholders
	widgetPlaceholderText: {
		color: "#cccccc", // Light grey text color
		textAlign: "center",
	},
	// Style for the actual widgets when in non-edit mode
	widgetContainer: {
		// Style similar to widgetPlaceholder but without the border
		padding: 20,
		marginBottom: 20,
	},
	gridContainer: {
		flex: 1,
		flexDirection: "row",
		flexWrap: "wrap",
		justifyContent: "center", // Center items horizontally
		alignItems: "center", // Align items vertically
		paddingVertical: 250, // Space for widget menu
		top: 750,
		position: "relative", // Ensure this is set
		// Define explicit dimensions if necessary
		width: "100%",
	},
	gridItem: {
		width: 400,
		height: 400,
		margin: 75,
		borderColor: "#cccccc",
		borderWidth: 2,
		borderRadius: 10,
		display: "flex",
		justifyContent: "center",
		alignItems: "center",
	},
	gridItemOccupied: {
		backgroundColor: "#f0f0f0",
		borderWidth: 1,
		borderColor: "#ccc",
	},
	deleteButton: {
		position: "absolute",
		top: 0,
		right: 0,
		backgroundColor: "red",
		padding: 5,
		borderRadius: 5,
		color: "white",
		fontWeight: "bold",
		zIndex: 100,
	},
	modalContainer: {
		flex: 1,
		justifyContent: "center",
		alignItems: "center",
		backgroundColor: "rgba(0, 0, 0, 0.5)",
	},
	modalContent: {
		width: "80%", // Adjust as needed
		backgroundColor: "#fefde2", // Or another color that fits your theme
		borderRadius: 20,
		padding: 20,
		shadowOffset: { width: 0, height: 2 },
		shadowOpacity: 0.25,
		shadowRadius: 3.84,
	},
	modalTitle: {
		fontFamily: fontFamilies.Bodoni72Smallcaps,
		fontSize: 25,
		textAlign: "center",
		color: "#4C956C", // Or any other color that fits your theme
		marginBottom: 15,
	},
	modalSubText: {
		fontFamily: fontFamilies.Bodoni72Smallcaps,
		fontSize: 25,
		textAlign: "center",
		color: "#4C956C", // Or any other color that fits your theme
		marginBottom: 15,
	},
	modalHeader: {
		fontFamily: fontFamilies.Bodoni72Smallcaps,
		fontSize: 16,
		textAlign: "left",
		color: "#4C956C", // Or any other color that fits your theme
		marginBottom: 2,
		paddingTop: 10
	},
	overlayBlur: {
		position: "absolute",
		top: 0,
		left: 0,
		right: 0,
		bottom: 0,
		// backgroundColor: "rgba(0,0,0,0.7)", // translucent black
		backdropFilter: "blur(25px)", // Adjust the px value to control the amount of blur
		zIndex: 1,
		height: maxViewHeight,
	},
	blurred: {
		position: "absolute",
		top: 100,
		left: 0,
		bottom: 0,
		right: 0,
		backdropFilter: "blur(10px)", // Apply blur effect
		// backgroundColor: "rgba(0, 0, 0, 0.5)", // You can adjust the alpha value for transparency
	},
	blurredContainer: {
		// Apply a blur effect
		position: "absolute",
		top: 0,
		bottom: 0,
		left: 0,
		right: 0,
		backdropFilter: "blur(10px)", // Apply blur effect
		zIndex: 9999,
	},

	shareButton: {
		marginTop: -25
	},
	backgroundModalView: {
		padding: 20,
		backgroundColor: "#fefde2", // Consider using a color that matches your app's theme
		borderRadius: 10,
		shadowColor: "#000",
		shadowOffset: { width: 0, height: 2 },
		shadowOpacity: 0.25,
		shadowRadius: 3.84,
		elevation: 5,
		margin: 20, // Ensures modal doesn't stretch to the edges of the screen
		alignItems: "center", // Centers the modal's content
	},
	applyButton: {
		backgroundColor: "#4c956c",
		padding: 10,
		borderRadius: 5,
		alignItems: "center",
	},
	applyButtonText: {
		color: "white",
		fontSize: isMobile ? 10 : isTablet ? 12 : isSmallDesktop ? 16 : 16,
		fontFamily: fontFamilies.Bodoni72Smallcaps,
	},
	stickyWarning: {
		position: 'absolute',
		top: 0,
		width: '100%',
		backgroundColor: '#ffcc00',
		padding: 10,
		paddingVertical: 15,
		zIndex: 1000,
		flexDirection: "row",
		alignItems: "center",
		justifyContent: "center",
	},
	closeButton: {
		backgroundColor: "red",
		padding: 10,
		borderRadius: 5,
		alignItems: "center",
		position: "absolute",
		left: 10,
	},
	closeButtonStickyWarning: {
		padding: 5,
		borderRadius: 5,
		alignItems: "center",
		position: "absolute",
		left: 10,
		backgroundColor: "red",
		paddingHorizontal: 10,
	},
	closeButtonText: {
		color: "white",
		fontSize: isMobile ? 10 : isTablet ? 12 : isSmallDesktop ? 16 : 16,
		fontFamily: fontFamilies.Bodoni72Smallcaps,
	},
	closeButtonTextcloseButtonStickyWarning: {
		color: "white",
		fontSize: isMobile ? 10 : isTablet ? 12 : isSmallDesktop ? 16 : 16,
	},
	combinedCardContainer: {
		flex: 1,
		width: "100%",
		height: "100%",
		alignItems: "center",
		justifyContent: "center",
		backgroundColor: "#fff",
		overflow: "hidden",
		borderWidth: 0,
		borderRadius: isMobile ? 15 : isTablet ? 20 : isSmallDesktop ? 25 : 30,
		borderColor: "#e4e4e4",
		marginBottom: isMobile ? 10 : isTablet ? 15 : isSmallDesktop ? 20 : 25,
		marginTop: isMobile ? 10 : isTablet ? 15 : isSmallDesktop ? 20 : 25,
		shadowOffset: { width: 0, height: 6 },
		shadowOpacity: 0.25,
		shadowRadius: 3.84,
		zIndex: 1000,
	},
	eventCard: {
		zIndex: 1001,
		shadowColor: "rgba(0, 0, 0, 0.5)",
		shadowOffset: { width: 0, height: 6 },
		shadowOpacity: 0.25,
		shadowRadius: 3.84,
		elevation: 5,
	},
	pageContainer: {
		flex: 1,
		alignItems: "center",
		justifyContent: "center",
		padding: 20,
	},
	cardContainer: {
		width: "100%",
		marginBottom: 20,
	},
	firstWidgetPicker: {
		flex: 1,
		alignItems: "center",
		justifyContent: "center",
		padding: 20,
		zIndex: 99,
	},
	picker: {
		height: 50,
		backgroundColor: "#fefde2",
		padding: 10,
		borderRadius: 5,
		alignItems: "center",
		marginTop: -100,
		width: "50%"
	},
	pickerItem: {
		width: "50%"
	},
	addButtonFirst: {
		backgroundColor: "#4c956c",
		padding: 10,
		borderRadius: 100,
		alignItems: "center",
		width: 40,
		height: 40,
		marginTop: 25
	},
	addButton: {
		backgroundColor: "#4c956c",
		padding: 10,
		borderRadius: 5,
		alignItems: "center",
		marginTop: 10,
	},
	addButtonText: {
		color: "#fff",
		fontSize: 16,
	},
	removeButton: {
		backgroundColor: "#ff4c4c",
		padding: 10,
		borderRadius: 5,
		alignItems: "center",
		marginTop: 10,
	},
	removeButtonText: {
		color: "#fff",
		fontSize: 16,
	},
	cardsContainer: {
		flex: 1,
		alignItems: "center",
		justifyContent: "center",

	},
	card: {
		width: "100%",
		marginVertical: 20, // Increased vertical margin to ensure the "Add" button moves down sufficiently
		padding: 20,
		backgroundColor: "transparent",
		borderRadius: 15,
		shadowColor: "rgba(0, 0, 0, 0.5)",
		shadowOffset: { width: 0, height: 6 },
		shadowOpacity: 0.25,
		shadowRadius: 3.84,
		elevation: 5,
	},

	buttonText: {
		color: "#fff",
		fontWeight: "bold",
	},
	scrollView: {
		flex: 1,
		padding: 10,
	},
	buttonContainer: {
		flexDirection: 'column',
		alignItems: 'center',
		padding: 10,
	},
	textInputOwner: {
		fontSize: 18,
		fontFamily: fontFamilies.Bodoni72Smallcaps,
		// other styles...
	},
	textInputViewer: {
		fontSize: 18,
		fontFamily: fontFamilies.Bodoni72Smallcaps,
		// other styles...
	},
	fetchMetadataButton: {
		backgroundColor: '#4c956c',
		padding: 10,
		borderRadius: 5,
		alignItems: 'center',
		marginTop: 10,
	},
	selectedButton: {
		shadowColor: "#000",
		shadowOffset: { width: 0, height: 2 },
		shadowOpacity: 0.25,
		shadowRadius: 3.84,
		elevation: 5,
		// other styles...
	},
});

export default styles;
