// URLPreview.js
import React from "react";
import { View, Text, TouchableOpacity, StyleSheet, Image } from "react-native";

const fontFamilies = {
	Bodoni72Smallcaps: "Bodoni72Smallcaps",
};

const URLPreview = ({ metadata, customImageUrl, url }) => {
	// Define a default image URL
	const defaultImageUrl = "https://via.placeholder.com/600"; // Placeholder image URL

	if (!metadata) {
		return <Text>Loading...</Text>;
	}

	// Use metadata image if available, otherwise use default image
	const imageUrl = customImageUrl || metadata.image || defaultImageUrl;
	// Click handler to redirect to the URL
	const handleClick = () => {
		if (url) {
			 const formattedUrl = url.startsWith("http://") || url.startsWith("https://") ? url : `https://${url}`;
			window.open(formattedUrl, "_blank");
		}
	};

	return (
		<TouchableOpacity onPress={handleClick} style={styles.container}>
			<Image 
				source={{ uri: imageUrl }} 
				style={styles.imagePreview}
				alt={metadata.title}
				loading="lazy"
			/>
			<Text style={styles.title}>{metadata.title}</Text>
		</TouchableOpacity>
	);
};

const styles = StyleSheet.create({
	container: {
		backgroundColor: "transparent",
		paddingHorizontal: "auto",
	},
	title: {
		fontFamily: fontFamilies.Bodoni72Smallcaps,
		color: "#fefde2", // Beige color
		fontSize: 20,
		textAlign: "center",
	},

	imagePreview: {
		width: "100%",
		height: "auto",
		borderRadius: 50,
	},

	description: {
		padding: "10px",
		marginTop: "10px",
		fontFamily: fontFamilies.Bodoni72Smallcaps,
		color: "#4C956C",
		fontSize: 15,
	},
});

export default URLPreview;
