import React, { createContext, useCallback, useState, useEffect, useRef, useContext } from "react";
import { View, Text, Linking, TextInput, ScrollView, ImageBackground, Modal, Animated, Button, Pressable, Image, Switch, Picker, Dimensions, TouchableOpacity } from "react-native";
import { getAuth, signInAnonymously, createUserWithEmailAndPassword, signInWithEmailAndPassword, onAuthStateChanged, signOut } from "firebase/auth";
import { getStorage, ref, uploadBytes, getDownloadURL, deleteObject } from "firebase/storage";

import { app } from "../firebase";
import { getFirestore, doc, getDoc, setDoc, query, where, getDocs, collection } from "firebase/firestore";

import { useUser } from "../Widgets/UserContext";
import { useNavigation, useFocusEffect } from "@react-navigation/native";
import styles from "../Styles/RegistryStyles";
import HeaderBar from "../Widgets/HeaderBar";
import Catalogue from "./RegistryWidgets/Catalogue";
import Yearbook from "./RegistryWidgets/Yearbook";
import GoalTracker from "./RegistryWidgets/GoalTracker";
import Share from "./Share";
import { BackgroundProvider } from './Helpers/BackgroundContext';
import PrivatePageMessage from "./Helpers/PrivatePageMessage"; // Import the new component
import { TutorialPopup, useTutorial } from "./Helpers/Tutorials";
import { buttonHoverStyle } from '../../utils/hoverStyles'; // Add this import at the top
import { REACT_APP_AWS_SERVER_URL } from '@env';	
import SEOHead from '../components/SEOHead';
import { ProductStructuredData } from '../components/StructuredData';

let LOCAL_SERVER_URL = "http://localhost:3300";
let AWS_SERVER_URL = REACT_APP_AWS_SERVER_URL;	

const isDevelopment = process.env.NODE_ENV === "development";
const SERVER_URL = isDevelopment ? LOCAL_SERVER_URL : AWS_SERVER_URL;

const { width, height } = Dimensions.get('window');
const isMobile = width < 768;
const isTablet = width >= 768 && width < 1024;
const isSmallDesktop = width >= 1024 && width <= 1500;
const isLargeDesktop = width > 1500;

const Registry = ({ route }) => {
	const {
		setShowTutorial,
		setActivePopupId,
		showTutorial,
		isLoading,
		tutorialsToShow,
		setClosedTutorials,
		setTutorialsToShow,
		activePopupId,
	} = useTutorial();

	//#region - Variables
	// Initialize Firestore
	const db = getFirestore(app);
	const { currentUser, currentUserData } = useUser();
	const [pageOwner, setPageOwner] = useState(null);
	const [isPageOwnerReady, setIsPageOwnerReady] = useState(false);
	const [showCatalogue, setShowCatalogue] = useState(false);
	const [registryItems, setRegistryItems] = useState([]);
	const [isAuthorized, setIsAuthorized] = useState(false);

	const navigation = useNavigation();

	// Since users can visit without being logged in, ensure currentUser is defined
	const auth = getAuth(app);

	const [showLogoutModal, setShowLogoutModal] = React.useState(false);
	const [isOwner, setIsOwner] = useState(false);
	const [showShippingAddressPopup, setShowShippingAddressPopup] = useState(false);
	const [shippingAddress, setShippingAddress] = useState("");
	//#endregion

	//#region - Testing Only Development
	const { setCurrentUser, setCurrentUserData } = useUser();

	useEffect(() => {
		const unsubscribe = onAuthStateChanged(auth, (authUser) => {
			if (authUser) {
				// Update the user in the context
				setCurrentUser(authUser);
				// Optionally update user data from Firestore
			} else {
				setCurrentUser(null);
				setCurrentUserData(null);
			}
		});

		return () => unsubscribe(); // Cleanup subscription
	}, []);

	useEffect(() => {
		if (isDevelopment) {
			const testLogin = async () => {
				try {
					const testEmails = [
						"Tjhawk27@gmail.com", // Trevor
						"trevorhawkinsofficial@gmail.com", // Olivia
						"trevorhawkins@makenoisemedia.io", //Sam
						"info@dsignable.com" // Tristan
					];
					const password = "Newtjhawk27";
					const lowerCaseEmail = testEmails[2].toLowerCase(); // Default to Jennifer

					const userCredential = await signInWithEmailAndPassword(auth, lowerCaseEmail, password);
					const user = userCredential.user;

					const userDocRef = doc(db, "users", lowerCaseEmail);
					const userDocSnap = await getDoc(userDocRef);

					if (userDocSnap.exists()) {
						const userData = userDocSnap.data();
						setCurrentUser(user); // Set the auth user
						setCurrentUserData(userData); // Set the Firestore user data
					} else {
						console.error("No such user data!");
					}
				} catch (error) {
					console.error("Error logging in with test account", error);
				}
			};
			// testLogin();
		}
	}, []);
	//#endregion

	//#region - Page Creation Variables
	const [editMode, setEditMode] = useState(false);
	const [editableMode, setEditableMode] = useState(false);
	const [widgets, setWidgets] = useState([]);
	const [pageOwnerId, setPageOwnerId] = useState(null);
	const [showAddItemModal, setShowAddItemModal] = useState(false);
	const [itemUrl, setItemUrl] = useState("");
	const [showItemModal, setShowItemModal] = useState(false);
	const [selectedItem, setSelectedItem] = useState(null);
	const [showCatalogueModal, setShowCatalogueModal] = useState(false);
	const [catalogueItems, setCatalogueItems] = useState([]);
	const [newItem, setNewItem] = useState({ name: "", description: "", price: "", image: null, url: "" });

	const [newItems, setNewItems] = useState([]);
	const sortedItems = [...newItems].sort((a, b) => {
		return Number(a.purchased) - Number(b.purchased) || a.name.localeCompare(b.name);
	});

	const [gridRows, setGridRows] = useState(1);
	const [selectedWidget, setSelectedWidget] = useState(null);
	const [showSavePopup, setShowSavePopup] = useState(false);
	const savePopupAnim = useRef(new Animated.Value(0)).current;
	const [showErrorPopup, setShowErrorPopup] = useState(false);
	const errorPopupAnim = useRef(new Animated.Value(0)).current;
	const [saveError, setSaveError] = useState("");

	const [showBackgroundModal, setShowBackgroundModal] = useState(false);
	const [backgroundImage, setBackgroundImage] = useState(null);
	const [applyBlur, setApplyBlur] = useState(false);
	const [textColor, setTextColor] = useState("#000000");
	const predefinedBackgrounds = [require("../assets/Popup Background.png"), require("../assets/Landing Background.png"), require("../assets/Hub Background.png")];
	const [selectedColor, setSelectedColor] = useState("#000000");
	const [showPurchaseConfirmation, setShowPurchaseConfirmation] = useState(false);
	const [selectedItemForPurchase, setSelectedItemForPurchase] = useState(null);
	const [isProcessing, setIsProcessing] = useState(false);

	const [showDidYouBuyPopup, setShowDidYouBuyPopup] = useState(false);

	// Add a new state to manage the visibility of the confirmation container
	const [showConfirmationContainer, setShowConfirmationContainer] = useState(false);

	const [hoveredButton, setHoveredButton] = useState(null);
	//#endregion

	//#region - Page Creation Functions
	const renderItem = (item, index) => {
		const itemStyle = item.purchased ? styles.purchasedItem : styles.itemCard;

		return (
			<Pressable
				key={index}
				onPress={() => {
					if (!item.purchased) {
						redirectToUrl(item);
						handleShowShippingAddress();
					}
				}}
				style={itemStyle}>
				{item.purchased && <Text style={styles.purchasedLabel}>Purchased</Text>}
				{item.image && <Image source={{ uri: item.image }} style={styles.itemImage} />}
				<Text style={[styles.itemName, { fontWeight: "bold", fontSize: isMobile ? 10 : 18 }]}>{truncateTitle(item.name)}</Text>
				<Text style={[styles.itemDescription, { fontSize: isMobile ? 8 : 18 }]}> {truncateDescription(item.description)}</Text>
				<Text style={[styles.itemPrice, { fontSize: isMobile ? 8 : 18 }]}>{`${item.price}`}</Text>

				{renderDeleteButton(index)}
			</Pressable>
		);
	};

	const deleteItem = async (itemIndex) => {
		if (currentUserData?.uid !== pageOwner?.uid) {
			alert("You are not authorized to delete this item.");
			// console.warn(currentUserData.uid);
			// console.warn(pageOwner.uid);
			return;
		}

		const itemToDelete = newItems[itemIndex];
		const storage = getStorage(app);

		if (itemToDelete.image && !itemToDelete.image.includes("amazon.com/images")) {
			const imageRef = ref(storage, itemToDelete.image);
			try {
				await deleteObject(imageRef);
			} catch (error) {
				// console.warn(`Error deleting image ${itemToDelete.image}:`, error);
			}
		}

		const updatedItems = newItems.filter((_, index) => index !== itemIndex);
		setNewItems(updatedItems);

		savePageConfig(updatedItems);
	};

	const toggleAddItemModal = () => setShowAddItemModal(!showAddItemModal);

	const toggleCatalogueModal = () => setShowCatalogueModal(!showCatalogueModal);

	const handleAddNewItem = async () => {
		if (!newItem.name || !newItem.description || !newItem.price || !newItem.image) {
			alert("Please fill out all fields and select an image before adding.");
			return;
		}

		try {
			const storageRef = ref(getStorage(app), `catalogueImages/${new Date().getTime()}_${newItem.name}`);
			const snapshot = await uploadBytes(storageRef, newItem.image);
			const imageUrl = await getDownloadURL(snapshot.ref);

			const itemToSave = {
				...newItem,
				image: imageUrl,
				url: newItem.url,
			};
			const newItemsRef = doc(collection(db, "catalogueItems"));
			await setDoc(newItemsRef, itemToSave);

			setNewItems((currentItems) => {
				const updatedItems = [...currentItems, itemToSave];
				savePageConfig(updatedItems);
				return updatedItems;
			});

			await savePageConfig();

			setNewItem({ name: "", description: "", price: "", image: null });
			setShowAddItemModal(false);
		} catch (error) {
			console.error("Error adding new item:", error);
			setShowSavePopup(false);
			setSaveError("Failed to add new item. Please try again.");
			setShowErrorPopup(true);
			Animated.timing(errorPopupAnim, {
				toValue: 1,
				duration: 500,
				useNativeDriver: true,
			}).start(() => {
				setTimeout(() => {
					Animated.timing(errorPopupAnim, {
						toValue: 0,
						duration: 500,
						useNativeDriver: true,
					}).start(() => setShowErrorPopup(false));
				}, 2000);
			});
		}
	};

	const loadCatalogueItems = async () => {
	};

	const [isAddingItems, setIsAddingItems] = useState(false);
	const [showSuccessMessage, setShowSuccessMessage] = useState(false);
	const [selectedItems, setSelectedItems] = useState([]);

	const addItemsToRegistry = async (items) => {
		setIsAddingItems(true);
		const userEmail = currentUser.email.toLowerCase();
		const userDocRef = doc(db, "users", userEmail);

		try {
			const docSnap = await getDoc(userDocRef);
			let currentRegistryPageConfig = {};
			if (docSnap.exists()) {
				currentRegistryPageConfig = docSnap.data().pages?.registryPageConfig || {};
			}

			// Convert keywords Set to array
			const updatedItems = [
				...(currentRegistryPageConfig.items || []),
				...items.map(item => ({
					...item,
					keywords: Array.isArray(item.keywords) ? item.keywords : Array.from(item.keywords || []), // Convert Set to array
				}))
			];

			const updatedRegistryPageConfig = { ...currentRegistryPageConfig, items: updatedItems };

			// console.log("Updated Registry Page Config:", updatedRegistryPageConfig); // Log the data structure

			await setDoc(userDocRef, { pages: { registryPageConfig: updatedRegistryPageConfig } }, { merge: true });
			// console.log("Registry items updated successfully in the database.");

			setRegistryItems(updatedItems);

			setShowSuccessMessage(true);
			setTimeout(() => setShowSuccessMessage(false), 1000);
		} catch (error) {
			console.error("Error updating registry items in Firestore:", error);
		} finally {
			setIsAddingItems(false);
		}
	};

	const handleImageSelection = (event) => {
		const file = event.target.files[0];
		if (file && file.type.startsWith("image/")) {
			setNewItem((prevState) => ({
				...prevState,
				image: file,
			}));
		}
	};

	const renderDeleteButton = (index) => {
		if (isOwner) {
			return (
				<Pressable style={styles.deleteButton} onPress={() => deleteItem(index)}>
					<Text>Delete</Text>
				</Pressable>
			);
		}
		return null;
	};

	const savePageConfig = async (updatedItems) => {
		const userEmail = currentUser.email.toLowerCase();
		const userDocRef = doc(db, "users", userEmail);

		try {
			const registryPageConfig = {
				items: updatedItems,
			};
			await setDoc(userDocRef, { pages: { registryPageConfig } }, { merge: true });

			// console.log("Configuration updated for", userEmail);

			setShowErrorPopup(false);
			setSaveError("");
			setShowSavePopup(true);
			Animated.timing(savePopupAnim, {
				toValue: 1,
				duration: 500,
				useNativeDriver: true,
			}).start(() => {
				setTimeout(() => {
					Animated.timing(savePopupAnim, {
						toValue: 0,
						duration: 500,
						useNativeDriver: true,
					}).start(() => setShowSavePopup(false));
				}, 2000);
			});
		} catch (error) {

			console.error("Error saving configuration for", userEmail, ":", error);

			setShowSavePopup(false);
			setSaveError("Failed to save configuration. Please try again.");
			setShowErrorPopup(true);
			Animated.timing(errorPopupAnim, {
				toValue: 1,
				duration: 500,
				useNativeDriver: true,
			}).start(() => {
				setTimeout(() => {
					Animated.timing(errorPopupAnim, {
						toValue: 0,
						duration: 500,
						useNativeDriver: true,
					}).start(() => setShowErrorPopup(false));
				}, 2000);
			});
		}
	};

	const extractUIDFromURL = () => {
		const url = window.location.href;
		// console.log(url);

		if (url.includes("username===")) {
			// console.log("url includes username===");
			const splitUrl = url.split("username===");
			// console.log(splitUrl);
			return splitUrl.length === 2 ? splitUrl[1] : null;
		} else if (url.includes("/registry/G")) {
			// console.log("url includes /registry/G");
			const regex = /\/registry\/(\w+)/;
			const match = regex.exec(url);
			if (match && match.length > 1) {
				const uid = match[1];
				// console.log("Extracted UID:", uid);
				return uid;
			} else {
				// console.log("Error extracting UID");
				return null;
			}
		} else if (url.includes("?username=")) {
			// console.log("url includes ?username");
			const params = new URLSearchParams(url.split("?")[1]);
			const uid = params.get("username");
			const filteredUID = uid.replace(/=/g, "");
			// console.log("Extracted UID:", uid);
			return filteredUID;
		} else if (url.includes("?0")) {
			// console.log("url includes /?");
			const regex = /[?&]0=%3D&1=%3D&2=%3D&3=(\w+)&4=(\d+)&5=(\d+)&6=(\d+)&7=(\d+)&8=(\d+)/;
			const match = regex.exec(url);
			// console.log(match);
			if (match) {
				var uid = match.slice(1).join("");
				// console.log("Extracted UID:", uid);
				return uid;
			} else {
				// console.log("Error Extracting UID");
				return null;
			}
		} else {
			// console.log("Error Extracting UID");
		}

		return null;
	};

	const loadPageConfig = useCallback(async () => {
		try {
			const uid = extractUIDFromURL();
			// console.warn("UID:", uid);

			if (!uid) {
				console.error("No UID found in URL");
				return;
			}

			const usersRef = collection(db, "users");
			const q = query(usersRef, where("uid", "==", uid));
			const querySnapshot = await getDocs(q);

			if (querySnapshot.empty) {
				// console.log("No user found with UID:", uid);
				setBackgroundImage(predefinedBackgrounds[0]);
				setApplyBlur(false);
				setWidgets([]);
				return;
			}

			querySnapshot.forEach((doc) => {
				const userData = doc.data();
				setPageOwner(userData);

				const eventsPageConfig = userData.pages?.eventsPageConfig;

				if (eventsPageConfig) {
					setBackgroundImage(eventsPageConfig.eventsCustomBackground || predefinedBackgrounds[0]);
					setApplyBlur(eventsPageConfig.eventsBlurEffect || false);
				} else {
					// console.log("No existing events page configuration found for UID:", uid);
					setBackgroundImage(predefinedBackgrounds[0]);
					setApplyBlur(false);
				}

				const registryPageConfig = userData.pages?.registryPageConfig;

				if (registryPageConfig) {
					setNewItems(registryPageConfig.items || []);
					// console.warn("NEW ITEMS:");
					// console.warn(newItems);

					setTextColor(registryPageConfig.registryTextColor || "#000000");
				} else {
					// console.log("No existing registry page configuration found for UID:", uid);
					setWidgets([]);
				}
			});
		} catch (error) {
			console.error("Error loading page configuration:", error);
		}
	}, [db, predefinedBackgrounds]);

	useFocusEffect(
		useCallback(() => {
			loadPageConfig();
		}, [currentUser])
	);

	const loadUserData = async () => {
		if (isPageOwnerReady) {
			return;
		}

		try {
			const uid = extractUIDFromURL();
			// console.warn(uid);

			if (!uid) {
				console.error("No UID found in URL");
				return;
			}

			setPageOwnerId(uid);

			const usersRef = collection(db, "users");
			const querySnapshot = await getDocs(query(usersRef, where("uid", "==", uid)));

			if (!querySnapshot.empty) {
				querySnapshot.forEach((doc) => {
					const userData = doc.data();
					setPageOwner(userData);
					// console.log(pageOwner);
					setIsPageOwnerReady(true);
				});
			} else {
				// console.log("No user found with UID:", uid);
			}
		} catch (error) {
			console.error("Error fetching user data:", error);
		}
	};

	const toggleEditMode = () => {
		setEditableMode(!editableMode);
		if (editableMode) {
			savePageConfig();
		}
	};

	const renderEditButton = () => {
		// Hide buttons when header tutorials are active
		const headerTutorials = ['friends', 'settings'];
		const shouldHideButtons = activePopupId && headerTutorials.includes(activePopupId);

		if (isOwner && !editableMode && !shouldHideButtons) {
			return (
				<>
				{/* Catalogue Tutorial */}
				<TutorialPopup
											id="registryCatalogue"
											position={{
												top: isMobile ? 160 : isSmallDesktop ? 160 : 160,
												right: isMobile ? 0 : isSmallDesktop ? 50 : 90
											}}
						title="Catalogue"
						openDirection="bottomLeft"
					/>

					<Pressable 
						style={[
							styles.modalToggleButton,
							buttonHoverStyle(false, 'catalogue', hoveredButton, isMobile, isTablet, isSmallDesktop)
						]}
						onPress={() => setShowCatalogueModal(true)}
						onMouseEnter={() => setHoveredButton('catalogue')}
						onMouseLeave={() => setHoveredButton(null)}
					>
						<Text style={[styles.editButtonText, { width: isMobile ? 200 : isSmallDesktop ? 250 : 300 }]}>Browse Catalogue</Text>
					</Pressable>
				</>
			);
		} else if (isOwner && editableMode) {
			return (
				<Pressable style={styles.editButton} onPress={toggleEditMode}>
					<Text style={styles.editButtonText}>View Page</Text>
				</Pressable>
			);
		}
	};

	const redirectToUrl = (item) => {
		let url = item.url;

		if (url) {
			setSelectedItemForPurchase(item);
			setShowShippingAddressPopup(true);
		}
	};

	const truncateDescription = (description, wordLimit = isMobile ? 10 : isTablet ? 20 : isSmallDesktop ? 20 : 30) => {
		let cleanedDescription = description.trim();

		const aboutThisItemPrefix = "About this item";

		cleanedDescription = cleanedDescription.replace(new RegExp(`^${aboutThisItemPrefix}\\s*`, "i"), "");

		const words = cleanedDescription.split(/\s+/);

		if (words.length > wordLimit) {
			return words.slice(0, wordLimit).join(" ") + "...";
		}

		// console.log(cleanedDescription);

		return cleanedDescription;
	};

	const truncateTitle = (title, wordLimit = isMobile ? 3 : isTablet ? 6 : isSmallDesktop ? 6 : 10) => {
		const words = title.split(" ");
		if (words.length > wordLimit) {
			return words.slice(0, wordLimit).join(" ") + "...";
		}
		return title;
	};

	const renderBackgroundButton = () => {
		if (isOwner && editableMode) {
			return (
				<Pressable style={styles.backgroundButton} onPress={() => setShowBackgroundModal(true)}>
					<Text style={styles.editButtonText}>Background and Colors</Text>
				</Pressable>
			);
		}
	};

	// Function to handle background image upload
	const handleBackgroundUpload = async (event) => {
		// Check if the event has a file list and the list is not empty
		if (event.target.files && event.target.files.length > 0) {
			const file = event.target.files[0];
			const storageRef = ref(getStorage(app), `eventBackgrounds/${currentUser.uid}/${file.name}`);
			uploadBytes(storageRef, file)
				.then((snapshot) => {
					getDownloadURL(snapshot.ref).then((downloadURL) => {
						setBackgroundImage(downloadURL);
						// Optionally, save the URL to Firestore here or in applyChanges
					});
				})
				.catch((error) => {
					console.error("Upload failed", error);
				});
		} else {
			console.error("No file selected");
		}
	};

	// Function to apply background and color changes
	const applyChanges = async () => {
		const userDocRef = doc(db, "users", currentUser.email.toLowerCase());

		// The document data should be an object
		const pageConfig = {
			registryCustomBackground: backgroundImage,
			registryTextColor: selectedColor,
			registryBlurEffect: applyBlur,
		};

		// Use setDoc to merge the new page config into the existing 'pages' object
		try {
			await setDoc(userDocRef, { pages: { registryPageConfig: pageConfig } }, { merge: true });
			setShowBackgroundModal(false);
		} catch (error) {
			console.error("Error applying changes:", error);
		}
	};

	// Function to toggle blur effect
	const toggleBlur = () => {
		setApplyBlur(!applyBlur);
	};

	// Blur
	const toggleModalBlur = (type = null) => {
		setIsBlurred(!isBlurred); // Toggle the blur
		setPopupType(type); // Set the popup type
	};
	//#endregion

	//#endregion

	//#region - Initialization
	// Call loadCatalogueItems when component mounts or when items are added/removed
	useEffect(() => {
		setTimeout(() => {
			loadUserData();
			loadPageConfig();
			loadCatalogueItems();
		}, 100);
	}, []);

	useEffect(() => {
		if (isPageOwnerReady && pageOwner) {
			// console.log("PageOwner updated:", pageOwner);
		} else {
			loadUserData();
			loadPageConfig();
		}
	}, [pageOwner]);

	// Inside the useEffect that checks for page ownership
	const checkOwnership = () => {
		if (!currentUserData || !pageOwnerId) {
			setIsOwner(false);
			return;
		}

		const owner = currentUserData.uid === pageOwnerId;
		setIsOwner(owner);
		// console.log("Logged In User:", currentUserData, "isPageOwner:", owner);
	};

	// Call this function within useEffect to check for ownership
	useEffect(() => {
		checkOwnership();
		// console.warn("currentUserData:", currentUserData);
	}, [currentUserData, pageOwnerId]);

	useEffect(() => {
		// console.warn("Registry Updated!", registryItems);
		loadPageConfig();
	}, [registryItems]);

	useEffect(() => {
		const checkAuthorization = async () => {
			if (!pageOwner) return;
			const urlParams = new URLSearchParams(window.location.search);
			const uic = urlParams.get('uic');

			if (uic && uic === pageOwner.uic) {
				setIsAuthorized(true);
				return;
			}

			if (pageOwner.uid === currentUserData?.uid) {
				setIsAuthorized(true);
				return;
			}

			if (!pageOwner.isFriendsListPrivate) {
				setIsAuthorized(true);
				return;
			}

			if (currentUserData) {
				if (pageOwner.friends?.followers && pageOwner.friends.followers[currentUserData.uid]) {
					setIsAuthorized(true);
					return;
				}
			}

			setIsAuthorized(false);
		};

		checkAuthorization();
	}, [pageOwner, currentUserData]);

	if (!isAuthorized && pageOwner) { // Ensure pageOwner is not null
		return <PrivatePageMessage pageOwner={pageOwner} />;
	}
	//#endregion

	// #region - Page Interaction Functions
	// Function to handle purchase confirmation
	const handleConfirmPurchase = async (item) => {
		setIsProcessing(true); // Start processing

		// Generate the formatted purchase time
		const formattedPurchaseTime = new Date().toLocaleString("en-US", {
			weekday: "long",
			year: "numeric",
			month: "long",
			day: "numeric",
			hour: "numeric",
			minute: "numeric",
			second: "numeric",
		});

		// Retrieve the current registryPageConfig from Firestore
		const userDocRef = doc(db, "users", pageOwner.email.toLowerCase());
		const docSnap = await getDoc(userDocRef);
		if (docSnap.exists()) {
			const registryPageConfig = pageOwner.pages?.registryPageConfig;

			if (registryPageConfig && registryPageConfig.items) {
				// Find the item in registryPageConfig.items and update it
				const updatedItems = registryPageConfig.items.map((it) => {
					if (it.id === item.id) {
						return { ...it, purchased: true, purchaseTime: formattedPurchaseTime };
					}
					return it;
				});

				// Update the registryPageConfig in Firestore
				await setDoc(
					userDocRef,
					{
						pages: {
							...docSnap.data().pages, // Preserve other page configurations
							registryPageConfig: { ...registryPageConfig, items: updatedItems },
						},
					},
					{ merge: true }
				);

				// Send purchase confirmation email
				await fetch(`${SERVER_URL}/send-purchase-confirmation-email`, {
					method: 'POST',
					headers: {
						'Content-Type': 'application/json',
					},
					body: JSON.stringify({
						itemDetails: item,
						pageOwner: pageOwner,
						purchaser: currentUserData,
					}),
				});

				// alert("Thank you for your purchase! The registry has been updated.");

				// Update local state if needed
				setRegistryItems(updatedItems);
			}
		} else {
			console.error("Document does not exist!");
		}

		setIsProcessing(false);
		setShowDidYouBuyPopup(false);
		setSelectedItemForPurchase(null); // Reset the selected item for purchase

		// Show the confirmation container
		setShowConfirmationContainer(true);
	};

	const handleShowShippingAddress = () => {
		if (pageOwner && pageOwner.shippingAddress) {
			setShippingAddress(pageOwner.shippingAddress);
			setShowShippingAddressPopup(true);
		} else {
			alert("This user has not set up a shipping address. Please contact them directly to ask where they want their gift sent.");
		}
	};

	// Function to handle showing the "Did you buy this?" popup
	const handleDidYouBuy = (item) => {
		setShowShippingAddressPopup(false);
		setSelectedItemForPurchase(item);
		setShowDidYouBuyPopup(true);
	};

	const handleAnnouncementsPress = () => {
		if (pageOwner && pageOwner.pages.events) {
			const urlParams = new URLSearchParams(window.location.search);
			const uic = urlParams.get('uic');

			let eventsUrl = pageOwner.pages.events;
			if (uic) {
				eventsUrl += `?uic=${uic}`; // Append the UIC to the events URL
			}

			Linking.openURL(eventsUrl);
		}
	};

	//#endregion

	// Add a new component to render the confirmation container
	const renderConfirmationContainer = () => {
		if (!showConfirmationContainer) return null;

		return (
			<View style={styles.fixedContainer}>
				<View style={styles.modalContainer}>
					<View style={styles.modalContent}>
						<Text style={styles.modalTitle}>Purchase Confirmed!</Text>
						<Text style={styles.confirmationText}>Thank you for your purchase! The registry has been updated.</Text>
						<TouchableOpacity style={styles.closeButton} onPress={() => setShowConfirmationContainer(false)}>
							<Text style={styles.buttonText}>Close</Text>
						</TouchableOpacity>
					</View>
				</View>
			</View>
		);
	};

	return (
		<>
			<SEOHead
				title={`${pageOwner?.firstName}'s Graduation Registry | MyTassel`}
				description={`View and contribute to ${pageOwner?.firstName}'s graduation registry from ${pageOwner?.organization?.name}`}
				image={pageOwner?.profilePictureURL}
				url={`https://mytassel.com/registry/${pageOwnerId}`}
				type="product"
			/>
			<ProductStructuredData 
				product={{
					name: `${pageOwner?.firstName}'s Graduation Registry`,
					description: `Graduation registry for ${pageOwner?.firstName} ${pageOwner?.lastName} from ${pageOwner?.organization?.name}`,
					// Add other product-specific structured data
				}}
			/>
			<View style={styles.body}>
				<View style={styles.container}>
					<ImageBackground source={backgroundImage ? { uri: backgroundImage } : require("../assets/Hub Background.png")} style={styles.backgroundImage} blurRadius={applyBlur || showCatalogueModal ? 10 : 0}>
						<ScrollView style={styles.body}>
							{/* Apply blur effect based on state */}
							{applyBlur && <View style={styles.overlayBlur} />}
							{/* Header Bar */}
							<HeaderBar />

							{/* Edit Button */}
							{isOwner && renderEditButton()}


							{/* Customization Button */}
							{renderBackgroundButton()}

							{/* Background Customization Modal */}
							{showBackgroundModal && (
								<Modal transparent={true} animationType="slide" visible={showBackgroundModal} onRequestClose={() => setShowBackgroundModal(false)}>
									<View style={styles.modalContainer}>
										<View style={styles.modalContent}>
											<Text style={styles.modalTitle}>Customize Background and Colors</Text>

											{/* Background Selection */}
											<View style={styles.backgroundOptions}>
												<Text>Select Background:</Text>
												<ScrollView horizontal={true}>
													{predefinedBackgrounds.map((bg, index) => (
														<Pressable key={index} onPress={() => setBackgroundImage(bg)}>
															<Image style={styles.backgroundThumbnail} source={bg} />
														</Pressable>
													))}
												</ScrollView>
												<Button title="Upload Custom Background" onPress={handleBackgroundUpload} />
											</View>

											{/* Color Picker */}
											<View style={styles.colorPicker}>
												<Text>Select Text Color:</Text>
												<Picker selectedValue={selectedColor} style={{ height: 50, width: 150 }} onValueChange={(itemValue, itemIndex) => setSelectedColor(itemValue)}>
													<Picker.Item label="Black" value="#000000" />
													<Picker.Item label="White" value="#FFFFFF" />
													{/* Add more color options as needed */}
												</Picker>
											</View>

											<View style={styles.blurToggle}>
												<Text tyle={styles.modalSubText}>Apply Blur Effect:</Text>
												<Switch value={applyBlur} onValueChange={toggleBlur} />
											</View>

											<Button title="Apply Changes" onPress={applyChanges} />
										</View>
									</View>
								</Modal>
							)}

							{/* Item Modal */}
							{showAddItemModal && (
								<View style={{ ...styles.blurredContainer }} visible={showAddItemModal} onRequestClose={() => setShowAddItemModal(false)}>
									<View style={styles.modalContent}>
										<TextInput style={styles.input} placeholder="Item Name" onChangeText={(text) => setNewItem({ ...newItem, name: text })} value={newItem.name} />
										<TextInput style={styles.input} placeholder="Description" onChangeText={(text) => setNewItem({ ...newItem, description: text })} value={newItem.description} />
										<TextInput style={styles.input} placeholder="Price" keyboardType="numeric" onChangeText={(text) => setNewItem({ ...newItem, price: text })} value={newItem.price} />
										<TextInput style={styles.input} placeholder="URL" onChangeText={(text) => setNewItem({ ...newItem, url: text })} value={newItem.url} />
										<input type="file" accept="image/*" onChange={handleImageSelection} />
										{newItem.image && <Image source={{ uri: newItem.image }} style={styles.imagePreview} />}
										<Button title="Add" onPress={handleAddNewItem} />
										<Button title="Close" onPress={() => setShowAddItemModal(false)} />
									</View>
								</View>
							)}

							{/* Catalogue Modal */}
							{showCatalogueModal && (
								<View style={{ ...styles.blurredContainer, zIndex: 1000 }} visible={showCatalogueModal} onRequestClose={() => setShowCatalogueModal(false)}>
									<View style={styles.catalogueModalContent}>
						
										
										<View style={styles.catalogueModalHeader}>
											<Pressable style={styles.catalogueCloseButton} onPress={() => setShowCatalogueModal(false)}>
												<Text style={styles.backButtonText}>X</Text>
											</Pressable>
											<Text style={styles.catalogueModalTitle}>Catalogue Items</Text>
										</View>

										{/* Catalogue Container */}
										<View style={styles.catelogueContainer}>
											<Catalogue
												isFromRegistry={true}
												onAddItems={addItemsToRegistry}
												showModal={showCatalogueModal}
												setShowModal={setShowCatalogueModal}
											/>
										</View>

										{/* Success Message */}
										{showSuccessMessage && <Text style={styles.successMessage}>Items added successfully!</Text>}
									</View>
								</View>
							)}

							{/* Modal for Showing Item Details */}
							{showItemModal && (
								<View styles={{ ...styles.blurredContainer }} transparent={true} visible={showItemModal} onRequestClose={() => setShowItemModal(false)}>
									<View style={styles.modalContent}>
										<Text style={styles.modalTitle}>{selectedItem?.name}</Text>
										<Image source={{ uri: selectedItem?.image }} style={styles.itemImage} />
										<Text style={styles.itemDescription}>{selectedItem?.description}</Text>
										<Text style={styles.itemPrice}>{`$${selectedItem?.price}`}</Text>
										<Button title="Go to URL" onPress={redirectToUrl} />
										<Button title="Close" onPress={() => setShowItemModal(false)} />
									</View>
								</View>
							)}

							<View style={styles.pageHeader}>
								{/* Yearbook Widget */}

								{!isMobile && (
									<View style={styles.yearbookContainer}>
										{pageOwnerId && (
											<View>
												<Yearbook style={styles.yearbookWidget} pageOwnerId={pageOwnerId} pageOwner={pageOwner} />
											</View>
										)}
										<View style={styles.shareContainer}>
											{pageOwner && pageOwner.pages.registry && <Share style={styles.shareButton} pageOwner={pageOwner} pageType={"Registry"} />}
										</View>
									</View>
								)}

								{/* Profile Image */}
								<View style={styles.profileContainer}>
									{pageOwner ? (
										<>
											{isOwner && showTutorial && (
												<>
													{/* Registry Intro Tutorial - near the top */}
													<TutorialPopup
														id="registryIntro"
														position={{
															top: isMobile ? 150 : 150,
															left: isMobile ? 150 : 150,
															zIndex: 100000
														}}
														title="Registry Page"
														openDirection="bottomRight"
														style={{ zIndex: 100000 }}
													/>
												</>
											)}
											<Image style={styles.profileImage} source={{ uri: pageOwner.profilePictureURL }} />
											{/* <Image style={styles.sealImage} source={require("../assets/Seal.png")} /> */}
											<Text style={styles.profileName}>{`${pageOwner.firstName} ${pageOwner.lastName}`}</Text>
											<Text style={styles.profileDetails}>{pageOwner.organization.name}</Text>
											<Text style={styles.profileDetailsSmall}>{pageOwner?.degree}</Text>
											{pageOwner.accountType === "graduate" && <Text style={styles.profileDetails}>Graduation Date: {pageOwner.graduationDate}</Text>}
										</>
									) : (
										// Optionally, add a placeholder or loader here while the data is being fetched
										<Text>Loading profile...</Text>
									)}
								</View>


								{/* Goal Widget */}
								{!isMobile && (
									<View style={[styles.goalTrackerContainer, { bottom: "10px" }]}>
										{/* Registry Header */}
										{pageOwnerId && !isOwner && pageOwner?.pages.registryPageConfig.goalTracker.targetAmount !== 0 && <Text style={styles.header}>{`${pageOwner?.firstName}'s Goal`}</Text>}
										{pageOwnerId && !isOwner && pageOwner?.pages.registryPageConfig.goalTracker.targetAmount !== 0 && <GoalTracker pageOwnerId={pageOwnerId} pageOwner={pageOwner} />}

										{pageOwnerId && isOwner && <Text style={styles.header}>{`My Goal`}</Text>}
										{pageOwnerId && isOwner && <GoalTracker pageOwnerId={pageOwnerId} pageOwner={pageOwner} isOwner={isOwner} />}

										{/* Go To Events Page */}
										{pageOwner && pageOwner.pages.events && (
											<Pressable 
												style={[
													styles.eventsButton ,
													{ top: "40px" },
													hoveredButton === 'events' && styles.eventsButtonHover
												]}
												onPress={handleAnnouncementsPress}
												onMouseEnter={() => setHoveredButton('events')}
												onMouseLeave={() => setHoveredButton(null)}
											>
												<Text style={styles.eventsButtonText}>
														Go to {pageOwner.firstName}'s <br />
														Announcement Page
												</Text>
											</Pressable>
										)}
									</View>
								)}
							</View>


							{/* Content Section */}
							<View style={styles.section}>
								{/* Grid Layout for Displaying Not Purchased Catalogue Items */}
								<View style={styles.gridContainer}>
									{newItems.filter((item) => !item.purchased).map((item, index) => renderItem(item, index))}
									{newItems.filter((item) => item.purchased).map((item, index) => renderItem(item, index))}
								</View>
							</View>
						</ScrollView>
					</ImageBackground>

					{showPurchaseConfirmation && !isOwner && (
						<View style={{ ...styles.blurredContainer }} visible={showPurchaseConfirmation} onRequestClose={() => setShowPurchaseConfirmation(false)}>
							<View style={styles.modalContainer}>
								{isProcessing ? (
									<Text style={styles.buttonText}>Thank you! Processing your purchase...</Text> // Show processing message
								) : (
									<View style={{ ...styles.blurredContainer }} visible={showPurchaseConfirmation} onRequestClose={() => setShowPurchaseConfirmation(false)}>
										<View style={styles.modalContent}>
											<Text style={styles.itemName}>Confirm Purchase</Text>
											<Text style={styles.itemName}>Did you purchase this item?</Text>
											<Text style={styles.itemName}>{selectedItemForPurchase?.name}</Text>
											<View style={styles.modalButtonGroup}>
												<Pressable style={styles.confirmButton} onPress={() => handleConfirmPurchase(selectedItemForPurchase)}>
													<Text style={styles.buttonText}>Yes, I did</Text>
												</Pressable>
												<Pressable style={{ ...styles.denyButton, backgroundColor: "red" }} onPress={() => setShowPurchaseConfirmation(false)}>
													<Text style={styles.buttonText}>No</Text>
												</Pressable>
											</View>
										</View>
									</View>
								)}
							</View>
						</View>
					)}

					{showShippingAddressPopup && (
						<View style={styles.fixedContainer} visible={showShippingAddressPopup} onRequestClose={() => setShowShippingAddressPopup(false)}>
							<View style={styles.modalContainer}>
								<View style={styles.modalContent}>
									<Text style={styles.modalTitle}>{pageOwner.firstName}'s Shipping Address</Text>
									<Text style={styles.shippingAddressText}>{shippingAddress}</Text>
									<TouchableOpacity style={styles.closeButton} onPress={() => setShowShippingAddressPopup(false)}>
										<Text style={styles.buttonText}>Close</Text>
									</TouchableOpacity>
									<TouchableOpacity style={styles.continueButton} onPress={() => {
										window.open(selectedItemForPurchase.url, "_blank").focus();
										handleDidYouBuy(selectedItemForPurchase);
									}}>
										<Text style={styles.buttonText}>Continue to Site</Text>
									</TouchableOpacity>
								</View>
							</View>
						</View>
					)}

					{showDidYouBuyPopup && (
						<View style={styles.fixedContainer} visible={showDidYouBuyPopup} onRequestClose={() => setShowDidYouBuyPopup(false)}>
							<View style={styles.modalContainer}>
								<View style={styles.modalContent}>
									<Text style={styles.modalTitle}>Did you purchase this item?</Text>
									<Text style={styles.itemName}>{selectedItemForPurchase?.name}</Text>
									<TouchableOpacity style={styles.confirmButton} onPress={() => handleConfirmPurchase(selectedItemForPurchase)}>
										<Text style={styles.buttonText}>Yes</Text>
									</TouchableOpacity>
									<TouchableOpacity style={styles.denyButton} onPress={() => setShowDidYouBuyPopup(false)}>
										<Text style={styles.buttonText}>No</Text>
									</TouchableOpacity>
								</View>
							</View>
						</View>
					)}

					{/* Save success popup */}
					{showSavePopup && (
						<Animated.View
							style={[
								styles.savePopup,
								{ opacity: savePopupAnim }, // Bind opacity to animated value
							]}>
							<Text style={styles.savePopupText}>Profile Updated Successfully!</Text>
						</Animated.View>
					)}

					{/* Save error popup */}
					{showErrorPopup && (
						<Animated.View
							style={[
								styles.errorPopup,
								{ opacity: errorPopupAnim }, // Bind opacity to animated value
							]}>
							<Text style={styles.errorPopupText}>{saveError}</Text>
						</Animated.View>
					)}

					{/* Render the confirmation container */}
					{renderConfirmationContainer()}
				</View>
			</View>
		</>
	);
};

export default Registry;