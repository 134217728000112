import React, { useEffect, useState } from 'react';
import { View, Text, StyleSheet, Pressable, ActivityIndicator } from 'react-native';
import { Dimensions } from 'react-native';
import { useNavigation } from '@react-navigation/native';
import { useUser } from '../../Widgets/UserContext';
import { getFirestore, doc, updateDoc } from 'firebase/firestore';
import { app } from '../../firebase';
import { REACT_APP_AWS_SERVER_URL } from '@env';	    

let LOCAL_SERVER_URL = "http://localhost:3300/send-access-request-email";
let AWS_SERVER_URL = REACT_APP_AWS_SERVER_URL + "/send-access-request-email";

const isDevelopment = process.env.NODE_ENV === "development";
const SERVER_URL = isDevelopment ? LOCAL_SERVER_URL : AWS_SERVER_URL;

const { width } = Dimensions.get('window');
const isMobile = width < 768;

const PrivatePageMessage = ({ pageOwner }) => {
    const navigation = useNavigation();
    const { currentUserData } = useUser();
    const db = getFirestore(app);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        // console.log(pageOwner, " in PrivatePageMessage");
        const timer = setTimeout(() => setIsLoading(false), 1000); // Set a timeout for loading
        return () => clearTimeout(timer); // Cleanup the timer
    }, [pageOwner]);

    const handleRequestAccess = async () => {
        if (!currentUserData) return;

        try {
            const pageOwnerRef = doc(db, 'users', pageOwner.email);
            await updateDoc(pageOwnerRef, {
                [`friends.requests.${currentUserData.uid}`]: {
                    email: currentUserData.email,
                    name: `${currentUserData.firstName} ${currentUserData.lastName}`,
                    profilePictureURL: currentUserData.profilePictureURL,
                },
            });

            await fetch(SERVER_URL, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    pageOwner: pageOwner,
                    requester: currentUserData,
                }),
            });

            alert('Access request sent successfully.');
            handleLinkPress('Hub');
        } catch (error) {
            console.error('Error requesting access:', error);
            alert('Failed to send access request.');
        }
    };

    const handleLinkPress = (pageName) => {
        // console.log(`Navigating to ${pageName}`);
        navigation.navigate(pageName);
    };

    if (isLoading) {
        return (
            <View style={styles.loadingContainer}>
                <ActivityIndicator size="large" color="#4c956c" />
                <Text style={styles.loadingText}>Authenticating...</Text>
            </View>
        );
    }

    return (
        <View style={styles.container}>
            <Text style={styles.messageText}>This page is private. You must be invited to view it.</Text>
            <Text style={styles.instructionsText}>If you believe you should have access, please request access or go back to the previous page.</Text>
            {currentUserData && (
                <Pressable style={styles.button} onPress={handleRequestAccess}>
                    <Text style={styles.buttonText}>Request Access</Text>
                </Pressable>
            )}
            <Pressable style={styles.button} onPress={() => handleLinkPress('Hub')}>
                <Text style={styles.buttonText}>Go Back</Text>
            </Pressable>
        </View>
    );
};

const styles = StyleSheet.create({
    loadingContainer: {
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: '#fefde2',
    },
    loadingText: {
        marginTop: 10,
        fontSize: 18,
        color: '#4c956c',
    },
    container: {
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: '#fefde2',
        padding: 20,
    },
    messageText: {
        fontFamily: 'Orpheus Pro',
        fontSize: isMobile ? 18 : 24,
        color: '#4c956c',
        textAlign: 'center',
        textShadowOffset: { width: 3, height: 3 },
        textShadowRadius: 6,
        textShadowColor: 'rgba(0, 0, 0, 0.09)',
        marginBottom: 20,
    },
    instructionsText: {
        fontFamily: 'Orpheus Pro',
        fontSize: isMobile ? 16 : 20,
        color: '#4c956c',
        textAlign: 'center',
        marginBottom: 20,
    },
    button: {
        backgroundColor: '#4c956c',
        padding: 15,
        borderRadius: 5,
        marginVertical: 10,
        width: '80%',
        alignItems: 'center',
    },
    buttonText: {
        color: 'white',
        fontSize: 16,
        fontWeight: 'bold',
    },
});

export default PrivatePageMessage;