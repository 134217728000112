import React, { useState, useEffect } from "react";
import { getFirestore, doc, getDoc, updateDoc } from "firebase/firestore";
import { useUser } from "../../Widgets/UserContext";
import { StyleSheet, View, Text, TouchableOpacity, Linking } from "react-native";
import { REACT_APP_AWS_SERVER_URL } from '@env';	
const CookiePolicyPopup = () => {
  const [isVisible, setIsVisible] = useState(false);
  const { currentUser } = useUser();
  const db = getFirestore();

  useEffect(() => {
    const checkCookieAcceptance = async () => {
      if (currentUser) {
        const userDocRef = doc(db, "users", currentUser.email);
        const userDoc = await getDoc(userDocRef);
        if (userDoc.exists() && !userDoc.data().hasAcceptedCookies) {
          setIsVisible(true);
        }
      }
    };

    checkCookieAcceptance();
  }, [currentUser, db]);

  const handleAccept = async () => {
    if (currentUser) {
      const userDocRef = doc(db, "users", currentUser.email);
      await updateDoc(userDocRef, { hasAcceptedCookies: true });
    }
    setIsVisible(false);
  };

  if (!isVisible) return null;

  const isDevelopment = process.env.NODE_ENV === "development";
  const SERVER_URL = isDevelopment ? "http://localhost:3300" : REACT_APP_AWS_SERVER_URL;
  const pdfFileUrl = `${SERVER_URL}/pdfs/Cookies Policy.pdf`;

  return (
    <View style={styles.cookiePolicyPopup}>
      <View style={styles.cookiePolicyContent}>
        <Text style={styles.cookiePolicyText}>
          We use cookies to ensure the proper functioning of our website, enhance your user experience, and collect analytical data to improve our services. By using our site, you consent to our use of cookies as described in our <Text style={styles.cookiePolicyLink} onPress={() => Linking.openURL(pdfFileUrl)}>Cookies Policy</Text>. Additionally, by using our sharing features, you consent to receive SMS messages when sharing pages. Message and data rates may apply, and you can opt out at any time by replying STOP to any message or contacting info@mytassel.com.
        </Text>
        <TouchableOpacity style={styles.acceptButton} onPress={handleAccept}>
          <Text style={styles.acceptButtonText}>Accept</Text>
        </TouchableOpacity>
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
 cookiePolicyPopup: {
  position: "fixed",
  bottom: 0,
  width: "100%",
  backgroundColor: "#4c956c",
  color: "#fefde2",
  textAlign: "center",
  padding: 10,
  boxShadow: "0 -2px 5px rgba(0, 0, 0, 0.2)",
  zIndex: 1000,
 },
 cookiePolicyContent: {
  flexDirection: "row",
  justifyContent: "space-between",
  alignItems: "center",
  width: "100%",
  marginHorizontal: 'auto',
  marginVertical: 0,
 },
 cookiePolicyText: {
  flex: 1,
  color: "#fefde2",
  textAlign: "left",
  maxWidth: "60%",
 },
 cookiePolicyLink: {
  color: "#00f",
  textDecorationLine: "underline",
 },
 acceptButton: {
  backgroundColor: "#2c6e49",
  border: "none",
  padding: 5,
  paddingHorizontal: 50,
  cursor: "pointer",
  right: 20, 
  borderRadius: 5,
 },
 acceptButtonText: {
  color: "#fefde2",
 },
 acceptButtonHover: {
  backgroundColor: "#1e4d34",
 },
});

export default CookiePolicyPopup;