import React, { useState, useEffect } from "react";
import { View, Text, ScrollView, StyleSheet, Platform, Button, Modal } from "react-native";
import { REACT_APP_AWS_SERVER_URL } from '@env';	
// Import Firebase
import { getAuth } from "firebase/auth";
import { getFirestore, doc, updateDoc } from "firebase/firestore"; 
import SMSPopup from "./SMSPopup";
import { useUser } from "../../Widgets/UserContext";

const Terms = () => {
	// URL to your PDF file - you might need to adjust this
	// For local files, ensure the file is accessible from the public folder in your web project

	let LOCAL_SERVER_URL = "http://localhost:3300";
	let AWS_SERVER_URL = REACT_APP_AWS_SERVER_URL;

	const { currentUser, currentUserData, setCurrentUser, setCurrentUserData } = useUser();

	const isDevelopment = process.env.NODE_ENV === "development";
	const SERVER_URL = isDevelopment ? LOCAL_SERVER_URL : AWS_SERVER_URL;

 const pdfFileUrl = `${SERVER_URL}/pdfs/Terms of Service.pdf`;

	const [modalVisible, setModalVisible] = useState(false);
	const [modalMessage, setModalMessage] = useState("");
	const [pdfError, setPdfError] = useState(false);

	const handleOptInOut = async () => {
		if (currentUserData) {
			const db = getFirestore();
			const userRef = doc(db, "users", currentUserData.email);
			await updateDoc(userRef, {
				smsOptIn: !currentUserData?.smsOptIn, // Toggle opt-in status
			});
			setModalMessage("Your SMS preferences have been updated.");
			// alert("Your SMS preferences have been updated.");
		} else {
			setModalMessage("You must log in first to opt in to SMS.");
			// alert("You must log in first to opt in to SMS.");
		}
		setModalVisible(true);
	};

	useEffect(() => {
		console.log("Opt In/Out Updated", "Opt-in currently set to", currentUserData?.smsOptIn );
	}, [currentUserData, modalVisible]);

	useEffect(() => {
		// Verify PDF URL is accessible
		const checkPdfAccess = async () => {
			try {
				const response = await fetch(pdfFileUrl);
				if (!response.ok) {
					console.error('PDF access error:', response.status);
					setPdfError(true);
				}
			} catch (error) {
				console.error('PDF fetch error:', error);
				setPdfError(true);
			}
		};
		
		checkPdfAccess();
	}, [pdfFileUrl]);

	return (
		<View style={styles.body}>
			<View style={styles.smsNotice}>
				<Text style={styles.smsNoticeText}>
					SMS and Text Message Communications Notice{"\n\n"}
					By using MyTassel's sharing features, you expressly consent to receive SMS messages from us. These may include sharing announcements and graduation information. Message and data rates may apply. You can opt out at any time by replying STOP to any message or contacting info@mytassel.com.
				</Text>
				<Button title={currentUserData?.smsOptIn ? "Opt Out of SMS" : "Opt In to SMS"} onPress={handleOptInOut} />
			</View>
			<SMSPopup
				visible={modalVisible}
				message={modalMessage}
				onClose={() => setModalVisible(false)}
			/>
			{/* <View style={styles.container}> */}
				{/* <Text style={styles.headerText}>Privacy Policy</Text> */}

				{/* <ScrollView style={{ flex: 1 }}> */}
					{/* <View style={styles.pdfContainer}> */}
					{Platform.OS === "web" ? (
						pdfError ? (
							<Text style={styles.errorText}>Error loading PDF. Please try again later.</Text>
						) : (
							<iframe 
								src={pdfFileUrl}
								style={styles.pdf}
								frameBorder="0"
								onError={() => setPdfError(true)}
							>
								Your browser does not support iframes.
							</iframe>
						)
					) : (
						<Text style={styles.text}>PDF display is not supported on this platform.</Text>
					)}
					{/* </View> */}
				{/* </ScrollView> */}
			{/* </View> */}
		</View>
	);
};

// Styles
const styles = StyleSheet.create({
	body: {
		flex: 1,
		// padding: 50,
		alignItems: "center",
		backgroundColor: "#4c956c",
  height: "100vh"
	},
	container: {
		flex: 1,
		padding: 50,
		backgroundColor: "#fefde2",
		width: "95vw",
		borderRadius: 75,
	},
	headerText: {
		fontFamily: "Bodoni72Smallcaps",
		fontWeight: "500",
		fontSize: 40,
		lineHeight: 30,
		textAlign: "center",
		color: "#4c956c",
		textShadowOffset: { width: 3, height: 3 },
		textShadowRadius: 6,
		textShadowColor: "rgba(0, 0, 0, 0.09)",
		marginBottom: 50,
	},
	pdfContainer: {
		flex: 1,
		width: "90vw", // Adjust as needed
		height: "100%", // Specify a fixed height or adjust as needed
		borderWidth: 1,
		borderColor: "#fff",
		borderRadius: 8,
		overflow: "hidden",
	},
	pdf: {
		width: "100%",
		height: "100vh",
		minHeight: 500,
	},
	text: {
		fontFamily: "Bodoni72Smallcaps",
		textAlign: "center",
		margin: 20,
	},
	smsNotice: {
		position: "absolute",
		top: 0,
		left: 0,
		right: 0,
		backgroundColor: "#4c956c",
		padding: 10,
		borderBottomWidth: 1,
		borderBottomColor: "#fff",
	},
	smsNoticeText: {
		fontFamily: "Bodoni72Smallcaps",
		fontSize: 16,
		color: "#fff",
		textAlign: "center",
		marginBottom: 10,
	},
	modalView: {
		margin: 20,
		backgroundColor: "white",
		borderRadius: 20,
		padding: 35,
		alignItems: "center",
		shadowColor: "#000",
		shadowOffset: {
			width: 0,
			height: 2,
		},
		shadowOpacity: 0.25,
		shadowRadius: 4,
		elevation: 5,
	},
	modalText: {
		marginBottom: 15,
		textAlign: "center",
	},
	errorText: {
		color: 'red',
		textAlign: 'center',
		margin: 20,
		fontFamily: "Bodoni72Smallcaps",
	},
});

export default Terms;
