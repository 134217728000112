import React from 'react';
import { View, StyleSheet } from 'react-native';
import { TutorialPopup } from '../Helpers/Tutorials';

const DemoVideo = ({ source, style }) => (
  <View style={[styles.videoContainer, style]}>
    <video
      autoPlay
      loop
      muted
      playsInline
      style={{
        width: '100%',
        height: '100%',
        objectFit: 'contain',
        borderRadius: 8,
      }}
    >
      <source src={source} type="video/mp4" />
      Your browser does not support the video tag.
    </video>
  </View>
);

const InteractiveTutorialDemo = ({ tutorial, demoType }) => {
  // Guard clause for when tutorial is null (during modal close)
  if (!tutorial || !demoType) return null;

  // Map tutorial IDs to their corresponding demo videos
  const getDemoSource = (id) => {
    const demoVideos = {
      // Hub Tutorials
      'welcome': require('../../assets/tutorials/welcome-demo.mp4'),
      'profile': require('../../assets/tutorials/profile-demo.mp4'),
      'announcement': require('../../assets/tutorials/announcement-demo.mp4'),
      'registry': require('../../assets/tutorials/registry-demo.mp4'),
      'resources': require('../../assets/tutorials/resources-demo.mp4'),
      
      // Announcement Features
      'eventsIntro': require('../../assets/tutorials/events-demo.mp4'),
      'combinedCardGoalTracker': require('../../assets/tutorials/goals-demo.mp4'),
      'combinedCardRegistry': require('../../assets/tutorials/contributions-demo.mp4'),
      'combinedCardShare': require('../../assets/tutorials/sharing-demo.mp4'),
      
      // Platform Navigation
      'navigation': require('../../assets/tutorials/navigation-demo.mp4'),
      'settings': require('../../assets/tutorials/settings-demo.mp4'),
    };

    return demoVideos[id];
  };

  return (
    <View style={styles.container}>
      <View style={styles.demoContainer}>
        <View style={styles.demoContent}>
          <DemoVideo 
            source={getDemoSource(demoType)}
            style={styles.demoVideo}
          />
          <TutorialPopup
            id={demoType}
            steps={tutorial.steps}
            position={{ top: 10, right: 10 }}
            title={tutorial.title}
          />
        </View>
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    padding: 20,
    backgroundColor: 'rgba(255, 255, 255, 0.9)',
    borderRadius: 10,
    marginTop: 20,
  },
  demoContainer: {
    minHeight: 300,
    width: '100%',
    position: 'relative',
  },
  demoContent: {
    padding: 15,
    backgroundColor: '#f8fafc',
    borderRadius: 8,
    position: 'relative',
  },
  videoContainer: {
    width: '100%',
    height: 300,
    backgroundColor: '#f8fafc',
    borderRadius: 8,
    overflow: 'hidden',
    marginBottom: 20,
    position: 'relative',
  },
  demoVideo: {
    width: '100%',
    height: '100%',
  }
});

export default InteractiveTutorialDemo; 