import React, { useState } from 'react';
import { View, Text, ImageBackground, Pressable, Modal, ScrollView } from 'react-native';
import { StyleSheet } from 'react-native';
import HeaderBar from '../Widgets/HeaderBar';
import InteractiveTutorialDemo from './Components/InteractiveTutorialDemo';

const TutorialModal = ({ isVisible, tutorial, onClose }) => (
  <Modal
    visible={isVisible}
    transparent={true}
    animationType="fade"
    onRequestClose={onClose}
  >
    <View style={styles.modalOverlay}>
      <View style={[styles.modalContent, { maxWidth: 800 }]}>
        <Text style={styles.modalTitle}>{tutorial?.title}</Text>
        <Text style={styles.modalDescription}>{tutorial?.description}</Text>
        
        <ScrollView style={styles.modalScroll}>
          <InteractiveTutorialDemo 
            tutorial={tutorial}
            demoType={tutorial?.id}
          />

          <View style={styles.modalSteps}>
            {tutorial?.steps?.map((step, index) => (
              <View key={index} style={styles.stepItem}>
                <Text style={styles.stepNumber}>{index + 1}</Text>
                <Text style={styles.stepText}>{step}</Text>
              </View>
            ))}
          </View>
        </ScrollView>
        
        <Pressable style={styles.closeButton} onPress={onClose}>
          <Text style={styles.closeButtonText}>Close</Text>
        </Pressable>
      </View>
    </View>
  </Modal>
);

const TutorialsScreen = () => {
  const [selectedTutorial, setSelectedTutorial] = useState(null);

  const tutorials = {
    hubTutorials: {
      title: "Getting Started",
      tutorials: [
        {
          id: 'welcome',
          title: 'Welcome to MyTassel',
          description: "Welcome to MyTassel! Let's get you started.",
          steps: [
            "Navigate through the platform using the menu bar",
            "Access your profile settings",
            "Explore available features and resources"
          ]
        },
        {
          id: 'profile',
          title: 'Profile Setup',
          description: 'Learn how to set up and customize your profile.',
          steps: [
            "Access your profile settings",
            "Update your personal information",
            "Add a profile picture",
            "Set your preferences"
          ]
        },
        {
          id: 'announcement',
          title: 'Announcement Creation',
          description: 'Create and manage your graduation announcements.',
          steps: [
            "Navigate to the Announcements section",
            "Create a new announcement",
            "Customize your announcement details",
            "Preview and publish your announcement"
          ]
        },
        {
          id: 'registry',
          title: 'Registry Management',
          description: 'Set up and manage your graduation registry.',
          steps: [
            "Access your registry settings",
            "Add items to your registry",
            "Organize registry categories",
            "Share your registry with others"
          ]
        },
        {
          id: 'resources',
          title: 'Resource Access',
          description: 'Access helpful resources and information.',
          steps: [
            "Find graduation planning resources",
            "Access checklists and guides",
            "Browse helpful articles",
            "Download planning materials"
          ]
        }
      ]
    },
    announcementTutorials: {
      title: "Announcement Features",
      tutorials: [
        {
          id: 'eventsIntro',
          title: 'Events Overview',
          description: 'Learn how to create and manage your events and announcements.',
          steps: [
            "Create new events",
            "Set event dates and times",
            "Add event locations",
            "Manage guest lists"
          ]
        },
        {
          id: 'combinedCardGoalTracker',
          title: 'Goal Tracking',
          description: 'Set and track your gift contribution goals.',
          steps: [
            "Set up contribution goals",
            "Track received contributions",
            "Monitor progress",
            "Manage thank-you notes"
          ]
        },
        {
          id: 'combinedCardRegistry',
          title: 'Accepting Contributions',
          description: 'Learn how to receive and manage monetary gifts for your graduation.',
          steps: [
            "Set up your payment account",
            "Configure contribution options",
            "Track received contributions", 
          ]
        },
        {
          id: 'combinedCardShare',
          title: 'Sharing Options',
          description: 'Share your announcement page with friends and family.',
          steps: [
            "Generate sharing links",
            "Send email invitations",
            "Share on social media",
            "Track who has viewed your announcement"
          ]
        }
      ]
    },
    platformTutorials: {
      title: "Platform Navigation",
      tutorials: [
        {
          id: 'navigation',
          title: 'Site Navigation',
          description: 'Learn how to navigate through MyTassel efficiently.',
          steps: [
            "Use the main navigation menu",
            "Access quick links",
            "Find help resources",
            "Use search features"
          ]
        },
        {
          id: 'settings',
          title: 'Account Settings',
          description: 'Manage your account preferences and settings.',
          steps: [
            "Update account information",
            "Manage privacy settings",
            "Set notification preferences",
            "Configure display options"
          ]
        }
      ]
    }
  };

  return (
    <View style={styles.body}>
      <ImageBackground 
        source={require("../assets/Popup Background.png")} 
        resizeMode="cover"
        style={styles.backgroundImage}
      >
        <HeaderBar />
        <View style={styles.overlayBlur} />
        
        <View style={styles.container}>
          <View style={styles.pageTitleContainer}>
            <Text style={styles.pageTitle}>MyTassel Tutorials</Text>
          </View>
          
          {Object.entries(tutorials).map(([category, { title, tutorials }]) => (
            <View key={category} style={styles.section}>
              <Text style={styles.sectionTitle}>{title}</Text>
              <View style={styles.tutorialGrid}>
                {tutorials.map((tutorial) => (
                  <Pressable 
                    key={tutorial.id}
                    style={({ pressed }) => [
                      styles.tutorialCard,
                      pressed && styles.tutorialCardPressed
                    ]}
                    onPress={() => setSelectedTutorial(tutorial)}
                  >
                    <View style={styles.cardContent}>
                      <Text style={styles.tutorialTitle}>{tutorial.title}</Text>
                      <Text style={styles.tutorialDescription} numberOfLines={3}>
                        {tutorial.description}
                      </Text>
                    </View>
                    <View style={styles.cardFooter}>
                      <Text style={styles.learnMore}>Learn More →</Text>
                    </View>
                  </Pressable>
                ))}
              </View>
            </View>
          ))}
        </View>

        <TutorialModal 
          isVisible={!!selectedTutorial}
          tutorial={selectedTutorial}
          onClose={() => setSelectedTutorial(null)}
        />
      </ImageBackground>
    </View>
  );
};

const styles = StyleSheet.create({
  body: {
    flex: 1,
    height: '100vh',

  },

  backgroundImage: {
    flex: 1,
    width: '100%',
  },

  overlayBlur: {
   position: "absolute",
   top: 0,
   bottom: 0,
   left: 0,
   right: 0,
   backdropFilter: "blur(10px)", // Apply blur effect
  },

  container: {
    padding: 40,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    paddingHorizontal: 50,
    alignItems: 'center',
    
  },

  pageTitleContainer: {
    padding: 5,
    backgroundColor: '#fefde2',
    borderRadius: 20,
    marginBottom: 20,
    marginTop: 20,
    minWidth: '60%',
  },

  pageTitle: {
    fontSize: 42,
    color: '#2c6e49',
    marginBottom: 40,
    fontFamily: 'Orpheus Pro',
    fontWeight: '600',
    textAlign: 'center',
    paddingTop: 20,
  },

  section: {
    marginBottom: 40,
    backgroundColor: '#fefde2',
    borderRadius: 20,
    padding: 30,
    boxShadow: '0 4px 6px rgba(0,0,0,0.05)',
    minWidth: '60%',
  },
  sectionTitle: {
    fontSize: 24,
    color: '#2c6e49',
    marginBottom: 20,
    fontWeight: '600',
    fontFamily: 'Orpheus Pro',
  },

  tutorialGrid: {
    display: 'grid',
    gridTemplateColumns: 'repeat(auto-fit, minmax(300px, 1fr))',
    gap: 24,
    padding: 10,
    minWidth: '60%',
  },

  tutorialCard: {
    backgroundColor: '#fefde2',
    borderRadius: 16,
    padding: 24,
    boxShadow: '0 2px 4px rgba(0,0,0,0.05)',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    minHeight: 200,
    border: '2px solid transparent',
    cursor: 'pointer',
    borderRadius: 15,
    boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
  },

  cardContent: {
    flex: 1,
  },

  tutorialTitle: {
    fontSize: 24,
    color: '#2c6e49',
    marginBottom: 12,
    fontWeight: '600',
    fontFamily: 'Orpheus Pro',
  },

  tutorialDescription: {
    fontSize: 16,
    color: '#475569',
    lineHeight: 24,
    fontFamily: 'Orpheus Pro',
  },

  cardFooter: {
    borderTop: '1px solid #e2e8f0',
    paddingTop: 15,
    marginTop: 20,
    fontFamily: 'Orpheus Pro',
  },

  learnMore: {
    color: '#2c6e49',
    fontSize: 16,
    fontWeight: '500',
    fontFamily: 'Orpheus Pro',
  },

  // Modal styles
  modalOverlay: {
    flex: 1,
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    justifyContent: 'center',
    alignItems: 'center',
  },

  modalContent: {
    backgroundColor: 'white',
    borderRadius: 20,
    padding: 30,
    width: '90%',
    maxWidth: 800,
    maxHeight: '90%',
    overflow: 'hidden',
    fontFamily: 'Orpheus Pro',
  },

  modalTitle: {
    fontSize: 28,
    color: '#2c6e49',
    marginBottom: 20,
    fontFamily: 'Orpheus Pro',
    fontWeight: '600',
  },

  modalDescription: {
    fontSize: 16,
    color: '#475569',
    marginBottom: 24,
    lineHeight: 1.6,
    fontFamily: 'Orpheus Pro',
  },

  stepItem: {
    flexDirection: 'row',
    alignItems: 'flex-start',
    marginBottom: 16,
    gap: 12,
  },

  stepNumber: {
    backgroundColor: '#f0fdf4',
    color: '#2c6e49',
    width: 24,
    height: 24,
    borderRadius: 12,
    textAlign: 'center',
    lineHeight: 24,
    fontWeight: '600',
    fontFamily: 'Orpheus Pro',
  },

  stepText: {
    flex: 1,
    fontSize: 16,
    color: '#475569',
    lineHeight: 1.5,
    fontFamily: 'Orpheus Pro',
    marginTop: 10,
  },

  closeButton: {
    backgroundColor: '#2c6e49',
    padding: 12,
    borderRadius: 8,
    alignItems: 'center',
    marginTop: 20,
    width: '100%',
  },

  closeButtonText: {
    color: 'white',
    fontSize: 16,
    fontWeight: '500',
    fontFamily: 'Orpheus Pro',
  },

  modalScroll: {
    flex: 1,
    width: '100%',
  },

  modalSteps: {
    marginTop: 20,
    paddingTop: 20,
    borderTopWidth: 1,
    borderTopColor: '#e2e8f0',
  },
});

export default TutorialsScreen; 