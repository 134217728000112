import React, { useState, useEffect } from 'react';
import { View, Text, ScrollView, Pressable, Image, StyleSheet, Animated, ImageBackground } from 'react-native';
import { getFirestore, collection, getDocs, query, orderBy, where } from 'firebase/firestore';
import HeaderBar from '../Widgets/HeaderBar';
// import SEOHead from '../Widgets/SEOHead';

const Blog = () => {
  const [posts, setPosts] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState('all');
  const [selectedPost, setSelectedPost] = useState(null);
  const db = getFirestore();

  const blogCategories = [
    "College Life",
    "Graduation Tips",
    "Dorm Living",
    "Study Tips",
    "Career Advice",
    "Financial Planning",
    "Student Life",
    "Moving Tips"
  ];

  const slideAnimBlog = React.useRef(new Animated.Value(-50)).current;

  const slideIn = () => {
    Animated.parallel([
      Animated.timing(slideAnimBlog, {
       
        toValue: 0,
        duration: 500,
        useNativeDriver: true,
      }),
    ]).start();
  };

  const slideOut = () => {
    Animated.parallel([
      Animated.timing(slideAnimBlog, {
        toValue: -50,
        duration: 500,
        useNativeDriver: true,
      }),
    ]).start();
  };

  useEffect(() => {
    console.log("Category changed to:", selectedCategory); // Debug log
    fetchPosts();
  }, [selectedCategory]); // This will run whenever selectedCategory changes

  const fetchPosts = async () => {
    try {
      console.log("Fetching posts for category:", selectedCategory); // Debug log
      let q;
      if (selectedCategory === 'all') {
        q = query(
          collection(db, "blog"), 
          orderBy("createdAt", "desc")
        );
      } else {
        q = query(
          collection(db, "blog"),
          where("category", "==", selectedCategory),
          orderBy("createdAt", "desc")
        );
      }
      
      const querySnapshot = await getDocs(q);
      const fetchedPosts = querySnapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data(),
        createdAt: doc.data().createdAt || new Date().toISOString(),
        tags: doc.data().tags || []
      }));

      console.log("Fetched posts:", fetchedPosts.length); // Debug log
      fetchedPosts.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
      
      setPosts(fetchedPosts);
    } catch (error) {
      console.error("Error fetching blog posts:", error);
      setPosts([]);
    }
  };

  const handleCategoryChange = (category) => {
    console.log("Handling category change to:", category); // Debug log
    setSelectedCategory(category);
    setSelectedPost(null);
  };

  const formatDate = (date) => {
    return new Date(date).toLocaleDateString('en-US', {
      year: 'numeric',
      month: 'long',
      day: 'numeric'
    });
  };

  return (
    <View style={styles.mainContainer}>
      <ImageBackground 
        source={require("../assets/Hub Background.png")} 
        style={styles.backgroundImage}
      >
        <View style={styles.overlayBlur} />
        <HeaderBar />
        <ScrollView style={styles.container}>
          <View style={styles.contentWrapper}>
            <Text style={styles.header}>MyTassel Blog</Text>
            
            <ScrollView horizontal showsHorizontalScrollIndicator={false} style={styles.categoryFilter}>
              <Pressable
                style={[
                  styles.categoryButton,
                  selectedCategory === 'all' && styles.selectedCategory
                ]}
                onPress={() => handleCategoryChange('all')}
              >
                <Text style={[
                  styles.categoryText,
                  selectedCategory === 'all' && styles.selectedCategoryText
                ]}>All</Text>
              </Pressable>
              {blogCategories.map(category => (
                <Pressable
                  key={category}
                  style={[
                    styles.categoryButton,
                    selectedCategory === category && styles.selectedCategory
                  ]}
                  onPress={() => handleCategoryChange(category)}
                >
                  <Text style={[
                    styles.categoryText,
                    selectedCategory === category && styles.selectedCategoryText
                  ]}>{category}</Text>
                </Pressable>
              ))}
            </ScrollView>

            <View style={styles.postsContainer}>
              {posts.length > 0 ? (
                posts.map(post => (
                  <Pressable 
                    key={post.id} 
                    style={styles.postCard}
                    onPress={() => setSelectedPost(selectedPost?.id === post.id ? null : post)}
                  >
                    {post.imageUrl && (
                      <Image 
                        source={{ uri: post.imageUrl }} 
                        style={styles.postImage}
                      />
                    )}
                    <View style={styles.postContent}>
                      <Text style={styles.postTitle}>{post.title}</Text>
                      <Text style={styles.postCategory}>{post.category}</Text>
                      <Text style={styles.postDate}>
                        {formatDate(post.createdAt)}
                      </Text>
                      <Text style={styles.postExcerpt}>
                        {selectedPost?.id === post.id ? 
                          post.content : 
                          post.content.substring(0, 150) + '...'}
                      </Text>
                      {post.tags && post.tags.length > 0 && (
                        <View style={styles.tagContainer}>
                          {post.tags.map((tag, index) => (
                            <Text key={index} style={styles.tag}>#{tag}</Text>
                          ))}
                        </View>
                      )}
                    </View>
                  </Pressable>
                ))
              ) : (
                <View style={styles.noPostsContainer}>
                  <Text style={styles.noPostsText}>No posts found in this category.</Text>
                </View>
              )}
            </View>
          </View>
        </ScrollView>
      </ImageBackground>
    </View>
  );
};

const styles = StyleSheet.create({
  mainContainer: {
    flex: 1,
  },
  backgroundImage: {
    flex: 1,
    width: '100%',
    height: '100%',
  },
  overlayBlur: {
    position: "absolute",
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    backdropFilter: "blur(10px)", // Apply blur effect
  },
  container: {
    flex: 1,
  },
  contentWrapper: {
    padding: 20,
    maxWidth: 1200,
    marginHorizontal: 'auto',
    width: '100%',
  },
  header: {
    fontSize: 48,
    fontWeight: 'bold',
    textAlign: 'center',
    marginVertical: 40,
    fontFamily: 'Orpheus Pro',
    color: '#fefde2',
    textShadowOffset: { width: 2, height: 2 },
    textShadowRadius: 4,
    textShadowColor: 'rgba(0, 0, 0, 0.25)',
  },
  categoryFilter: {
    marginBottom: 30,
    paddingHorizontal: 10,
  },
  categoryButton: {
    padding: 12,
    marginRight: 15,
    borderRadius: 25,
    backgroundColor: 'rgba(255, 255, 255, 0.9)',
    minWidth: 100,
    alignItems: 'center',
    shadowColor: '#000',
    shadowOffset: { width: 0, height: 2 },
    shadowOpacity: 0.1,
    shadowRadius: 4,
    elevation: 3,
  },
  selectedCategory: {
    backgroundColor: '#2c6e49',
  },
  categoryText: {
    color: '#2c6e49',
    fontSize: 16,
    fontWeight: '500',
  },
  selectedCategoryText: {
    color: '#fff',
  },
  postsContainer: {
    gap: 30,
  },
  postCard: {
    backgroundColor: 'rgba(255, 255, 255, 0.95)',
    borderRadius: 15,
    overflow: 'hidden',
    shadowColor: '#000',
    shadowOffset: { width: 0, height: 4 },
    shadowOpacity: 0.1,
    shadowRadius: 8,
    elevation: 5,
    marginBottom: 30,
    transition: 'transform 0.2s ease',
    cursor: 'pointer',
    ':hover': {
      transform: 'translateY(-5px)',
    },
  },
  postImage: {
    width: '100%',
    height: 300,
    resizeMode: 'cover',
  },
  postContent: {
    padding: 25,
  },
  postTitle: {
    fontSize: 28,
    fontWeight: 'bold',
    marginBottom: 15,
    fontFamily: 'Orpheus Pro',
    color: '#2c6e49',
  },
  postCategory: {
    color: '#2c6e49',
    marginBottom: 8,
    fontSize: 16,
    fontWeight: '500',
  },
  postDate: {
    color: '#666',
    marginBottom: 15,
    fontSize: 14,
  },
  postExcerpt: {
    fontSize: 16,
    lineHeight: 24,
    color: '#333',
    marginBottom: 20,
  },
  noPostsContainer: {
    padding: 40,
    alignItems: 'center',
    justifyContent: 'center',
  },
  noPostsText: {
    fontSize: 18,
    color: '#666',
    textAlign: 'center',
  },
  tagContainer: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    gap: 8,
    marginTop: 15,
  },
  tag: {
    backgroundColor: '#f0f0f0',
    paddingHorizontal: 12,
    paddingVertical: 6,
    borderRadius: 20,
    fontSize: 14,
    color: '#2c6e49',
    fontWeight: '500',
  }
});

export default Blog; 