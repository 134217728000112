import React from 'react';

export const EventStructuredData = ({ event }) => {
  return (
    <script type="application/ld+json">
      {JSON.stringify({
        "@context": "https://schema.org",
        "@type": "Event",
        ...event
      })}
    </script>
  );
};

export const ProductStructuredData = ({ product }) => {
  return (
    <script type="application/ld+json">
      {JSON.stringify({
        "@context": "https://schema.org",
        "@type": "Product",
        ...product
      })}
    </script>
  );
}; 