import React, { useState, useEffect, useRef } from "react";
import { Animated, Text, View, TextInput, ScrollView, ImageBackground, Modal, Button, Pressable, Switch, Image, TouchableOpacity, Alert, Dimensions, StyleSheet } from "react-native";
import { getFirestore, doc, setDoc, updateDoc } from "firebase/firestore";
import { getAuth, signInAnonymously, createUserWithEmailAndPassword, signInWithEmailAndPassword, onAuthStateChanged, signOut, updateEmail, updatePassword } from "firebase/auth";
import { getDoc, collection, onSnapshot } from "firebase/firestore";
import { initializeApp } from "firebase/app";
import { firebaseConfig, app } from "../firebase";
import { useNavigation } from "@react-navigation/native";
import { getStorage, ref, uploadBytes, getDownloadURL } from "firebase/storage"; // Import Firebase Storage
import { useUser } from "./UserContext";
import Popup from "./Popup";
import PromptSignUp from "./PromptSignUp";
import Friends from "./Friends";
import friendsIcon from "../assets/friends.png";
import friendsNotificationIcon from "../assets/friends-notification.png"; // Added import for friendsNotificationIcon
import settingsIcon from "../assets/settings.png"; // Import settings icon
import { useTutorial, TutorialPopup } from '../Screens/Helpers/Tutorials'; // Import the tutorial context

const { width, height } = Dimensions.get('window');
const isMobile = width < 768;
const isTablet = width >= 768 && width < 1024;
const isSmallDesktop = width >= 1024 && width < 1440;
const isLargeDesktop = width >= 1440;


// Initialize Firestore and other Firebase services
const db = getFirestore(app);
const storage = getStorage(app); // Initialize Firebase Storage

const fontFamilies = {
	Bodoni72Smallcaps: "Bodoni72Smallcaps",
};

// Add this function near the top with other functions
const initializeTutorialSetting = async (userEmail) => {
	try {
		const userDoc = doc(db, "users", userEmail);
		const userDocSnap = await getDoc(userDoc);
		
		if (userDocSnap.exists()) {
			const userData = userDocSnap.data();
			// If showTutorial is undefined, set it to true (default)
			if (userData.showTutorial === undefined) {
				await updateDoc(userDoc, { showTutorial: true });
				return true;
			}
			return userData.showTutorial;
		} else {
			// If user document doesn't exist, create it with showTutorial set to true
			await setDoc(userDoc, { showTutorial: true }, { merge: true });
			return true;
		}
	} catch (error) {
		console.error("Error initializing tutorial setting:", error);
		return true; // Default to true if there's an error
	}
};

const HeaderBar = () => {
	const { currentUser, currentUserData } = useUser();
	const navigation = useNavigation();
	const auth = getAuth(app);
	const [showPromptSignUp, setShowPromptSignUp] = useState(false);
	const [showFriends, setShowFriends] = useState(false);
	const [showLogoutModal, setShowLogoutModal] = React.useState(false);
	const [friendRequests, setFriendRequests] = useState([]); // Added state for friendRequests
	const [showSettingsModal, setShowSettingsModal] = useState(false); // State for settings modal
	const [isPrivacyPublic, setIsPrivacyPublic] = useState(false); // State for privacy toggle
	const [showPrivacyWarning, setShowPrivacyWarning] = useState(false); // State for privacy warning
	const friendsRef = useRef(null); // Ref for Friends component
	const { closeTutorial, showTutorial, setShowTutorial, closedTutorials, isLoading, tutorialsToShow, setActivePopupId, handleHover } = useTutorial(); // Use the tutorial context
	const [isTutorialEnabled, setIsTutorialEnabled] = useState(false);

	useEffect(() => {
		if (!auth.currentUser?.email) return;

		// console.log('Setting up real-time friend requests listener');
		const userDoc = doc(db, "users", auth.currentUser.email);

		const unsubscribe = onSnapshot(userDoc, (docSnapshot) => {
			if (docSnapshot.exists()) {
				const userData = docSnapshot.data();
				const requests = userData.friends?.requests || {};
				// console.log('Real-time update received for friend requests:', requests);
				setFriendRequests(Object.keys(requests));
			}
		});

		return () => unsubscribe();
	}, [auth.currentUser]);

	const friendsIconToShow = friendRequests.length > 0 ? friendsNotificationIcon : friendsIcon; // Conditional rendering of friendsIcon

	// Handle Logout
	const handleLogout = async () => {
		try {
			// Sign out from Firebase Auth
			await signOut(auth);


			// Navigate to the welcome or landing screen
			navigation.navigate("My Tassel - Welcome");
		} catch (error) {
			console.error("Error during logout:", error);
		}
	};

	// Function to toggle between Login and Logout button based on user state
	const renderLoginOrLogoutButton = () => {
		if (currentUser || currentUserData) {
			return (
				<Pressable style={styles.loginButton} onPress={() => setShowLogoutModal(true)}>
					<Text style={styles.loginButtonText}>Logout</Text>
				</Pressable>
			);
		} else {
			return (
				<Pressable style={styles.loginButton} onPress={() => navigation.navigate("My Tassel - Welcome", { popupType: "login" })}>
					<Text style={styles.loginButtonText}>Login</Text>
				</Pressable>
			);
		}
	};

	// Handle Link Press
	const handleLinkPress = (pageName) => {
		// console.log(`Navigating to ${pageName}`);
		navigation.navigate(pageName); // Use the navigate function to change the page.
	};

	const generateUIC = () => {
		return Math.random().toString(36).substr(2, 9); // Simple UIC generation logic
	};

	useEffect(() => {
		const updatePrivacyInFirestore = async () => {
			try {
				if (!currentUserData || !currentUserData.email) {
					// console.error("User is not authenticated or email is not available");
					return;
				}

				const userDoc = doc(db, "users", currentUserData.email);
				const updates = {
					isFriendsListPrivate: !isPrivacyPublic
				};

				// Only generate new UIC when switching from public to private
				if (!isPrivacyPublic && !currentUserData.uic) {
					updates.uic = generateUIC();
				}

				await updateDoc(userDoc, updates);

				if (friendsRef.current) {
					friendsRef.current.setPrivacy(!isPrivacyPublic);
				}
			} catch (error) {
				console.error("Error updating privacy setting:", error);
			}
		};

		updatePrivacyInFirestore();
	}, [isPrivacyPublic, currentUserData]);

	// Handle Privacy Toggle
	const handlePrivacyToggle = () => {
		if (!isPrivacyPublic) {
			setShowPrivacyWarning(true);
		} else {
			setIsPrivacyPublic(false);
		}
	};

	// Confirm Privacy Toggle
	const confirmPrivacyToggle = () => {
		setIsPrivacyPublic(true);
		setShowPrivacyWarning(false);
	};

	const fetchPrivacySetting = async () => {
		if (!currentUserData || !currentUserData.email) {
			// console.error("User is not authenticated or email is not available");
			return;
		}

		const userDoc = await getDoc(doc(db, "users", currentUserData.email));
		if (userDoc.exists()) {
			const userData = userDoc.data();
			setIsPrivacyPublic(!userData.isFriendsListPrivate);
			// alert(`Privacy is set to: ${!userData.isFriendsListPrivate}`);
		} else {
			setIsPrivacyPublic(false); // Default to false if the field is not found
			// alert("Privacy is set to: false");
		}
	};

	useEffect(() => {
		fetchPrivacySetting();
	}, [currentUserData]);

	// Function to update tutorial preference in Firestore
	const updateTutorialPreference = async (showTutorial) => {
		if (!currentUserData || !currentUserData.email) return;
		const userDoc = doc(db, "users", currentUserData.email);
		await updateDoc(userDoc, { showTutorial });
	};

	// Handle Tutorial Toggle
	const handleTutorialToggle = async (value) => {
		if (!currentUserData?.email) return;

		try {
			// console.log("Toggling tutorial preference to:", value);
			const userDoc = doc(db, "users", currentUserData.email);
			await updateDoc(userDoc, { 
				showTutorial: value,
				// Reset closed tutorials when enabling tutorials
				completedTutorials: value ? {} : (await getDoc(userDoc)).data()?.completedTutorials || {}
			});
			setIsTutorialEnabled(value);
			setShowTutorial(value);
		} catch (error) {
			console.error("Error updating tutorial preference:", error);
			// Revert the toggle if there's an error
			setIsTutorialEnabled(!value);
			setShowTutorial(!value);
		}
	};

	const tutorialSteps = {
		friends: "Connect with friends and manage your friend requests here",
		settings: "Customize your account settings and tutorial preferences"
	};

	const totalTutorials = Object.keys(tutorialSteps).length;

	useEffect(() => {
		const fetchTutorialPreference = async () => {
			if (!currentUserData?.email) return;

			try {
				const initialValue = await initializeTutorialSetting(currentUserData.email);
				// console.log("Initial tutorial preference:", initialValue);
				setIsTutorialEnabled(initialValue);
				setShowTutorial(initialValue);
			} catch (error) {
				console.error("Error fetching tutorial preference:", error);
				// Default to true if there's an error
				setIsTutorialEnabled(true);
				setShowTutorial(true);
			}
		};

		fetchTutorialPreference();
	}, [currentUserData]);

	const handleTutorialActivation = (popupId) => {
		setActivePopupId(popupId); // Set the active popup ID
	};

	return (
		<View style={styles.headerBar}>
			{currentUserData ?
				<Pressable style={styles.logoArea} onPress={() => handleLinkPress("Hub")}>
					<Text style={styles.logoText}>MYTASSEL</Text>
				</Pressable>
				:
				<Pressable style={styles.logoArea} onPress={() => handleLinkPress("My Tassel - Welcome", { popupType: "login" })}>
					<Text style={styles.logoText}>MYTASSEL</Text>
				</Pressable>
			}
			<View style={styles.loginArea}>
				{currentUserData ?
					<Pressable style={styles.backButton} onPress={() => handleLinkPress("Hub")}>
						<Text style={styles.backButtonText}>{`Go Home`}</Text>
					</Pressable>
					:
					<Pressable style={styles.backButton} onPress={() => navigation.navigate("My Tassel - Welcome", { popupType: "login" })}>
						<Text style={styles.backButtonText}>{`Sign Up`}</Text>
					</Pressable>
				}

				<View style={styles.loginArea}>{renderLoginOrLogoutButton()}</View>

				<TouchableOpacity onPress={() => {
					if (currentUserData) {
						setShowFriends(true)
					}
					else {
						setShowPromptSignUp(true)
					}
				}}>
					<Image source={friendRequests.length > 0 ? friendsNotificationIcon : friendsIcon} style={styles.icon} />
				</TouchableOpacity>


				<TouchableOpacity onPress={() => {
					setShowSettingsModal(true);
					fetchPrivacySetting();
				}}>
					<Image source={settingsIcon} style={[styles.icon, { width: isMobile ? 20 : isSmallDesktop ? 25 : 30, height: isMobile ? 20 : isSmallDesktop ? 25 : 30, position: 'absolute', top: isMobile ? -10 : isSmallDesktop ? -12 : -16, right: isMobile ?  -120 : isSmallDesktop ? -25 : -45, zIndex: 10001, }]} />
				</TouchableOpacity>

				{currentUserData ? (
					<Friends
						isVisible={showFriends}
						onClose={() => setShowFriends(false)}
						ref={friendsRef}
						isFriendsListPrivate={isPrivacyPublic} // Pass the state as a prop
						onPrivacyToggle={(privacy) => setIsPrivacyPublic(!privacy)}
						showPrivacyWarning={showPrivacyWarning}
						setShowPrivacyWarning={setShowPrivacyWarning}
						confirmPrivacyToggle={confirmPrivacyToggle}
					/>
				) : (
					<PromptSignUp isVisible={showPromptSignUp} onClose={() => setShowPromptSignUp(false)} redirectPage="Friends" />
				)}
				<PromptSignUp isVisible={showPromptSignUp} onClose={() => setShowPromptSignUp(false)} redirectPage="Profile" />
			</View>



			{/* Sign Out Confirmation */}
			{showLogoutModal && (
				<View style={styles.blurredContainer}>
					<Modal transparent={true} animationType="slide" visible={showLogoutModal} onRequestClose={() => setShowLogoutModal(false)}>
						<View style={styles.overlayBlur} />
						<View style={styles.centeredView}>
							<View style={styles.modalContainer}>
								<View style={styles.modalContent}>
									<Text style={styles.modalTitle}>Are you sure you want to logout?</Text>
									<View style={styles.modalButtonContainer}>
										<Pressable
											style={styles.modalButton}
											onPress={() => {
												handleLogout();
												setShowLogoutModal(false);
											}}>
											<Text style={styles.modalButtonText}>Sign Out</Text>
										</Pressable>
										<Pressable
											style={[styles.modalButton, styles.cancelButton]}
											onPress={() => setShowLogoutModal(false)}>
											<Text style={styles.modalButtonText}>Cancel</Text>
										</Pressable>
									</View>
								</View>
							</View>
						</View>
					</Modal>
				</View>
			)}

			{/* Settings Modal */}
			{showSettingsModal && (
				<View style={styles.blurredContainer}>
					<Modal transparent={true} animationType="slide" visible={showSettingsModal} onRequestClose={() => setShowSettingsModal(false)}>
						<View style={styles.overlayBlur} />
						<View style={styles.centeredView}>
							<View style={styles.modalContainer}>
								<View style={styles.modalContent}>
									<Text style={styles.modalTitle}>Settings</Text>
									<View style={styles.settingItem}>
										<Text style={styles.settingText}>Account Privacy </Text>
										<Switch value={!isPrivacyPublic} onValueChange={handlePrivacyToggle} />
									</View>
									<View style={styles.settingItem}>
										<Text style={styles.settingText}>Show Tutorials</Text>
										<Switch 
											value={isTutorialEnabled}
											onValueChange={handleTutorialToggle}
										/>
									</View>
									<Pressable style={styles.closeModalButton} onPress={() => setShowSettingsModal(false)}>
										<Text style={styles.modalButtonText}>Close</Text>
									</Pressable>
								</View>
							</View>
						</View>
					</Modal>
				</View>
			)}

			{/* Privacy Warning Modal */}
			{showPrivacyWarning && (
				<View style={styles.blurredContainer}>
					<Modal transparent={true} animationType="slide" visible={showPrivacyWarning} onRequestClose={() => setShowPrivacyWarning(false)}>
						<View style={styles.overlayBlur} />
						<View style={styles.centeredView}>
							<View style={styles.modalContainer}>
								<View style={styles.modalContent}>
									<Text style={styles.modalTitle}>Privacy Warning</Text>
									<Text style={styles.warningText}>Making your page public will share your info with anyone, including your address if you create a registry.</Text>
									<Pressable style={styles.modalButton} onPress={confirmPrivacyToggle}>
										<Text style={styles.modalButtonText}>I Understand</Text>
									</Pressable>
									<Pressable style={styles.closeModalButton} onPress={() => setShowPrivacyWarning(false)}>
										<Text style={styles.modalButtonText}>Cancel</Text>
									</Pressable>
								</View>
							</View>
						</View>
					</Modal>
				</View>
			)}

			{/* Only render tutorials after loading and if they're in tutorialsToShow */}
			{!isLoading && showTutorial && (
				<>
					{tutorialsToShow.includes('friends') && (
						<TutorialPopup
							id="friends"
							steps={tutorialSteps.friends}
							position={{
								top: isSmallDesktop ? 50 : 50,
								right: isSmallDesktop ? 115 : 115
							}}
							title="Friends"
							openDirection="bottomLeft"
							totalTutorials={tutorialsToShow.length}
							style={{ zIndex: 100010 }}
							onHover={() => handleHover('friends')}
						/>
					)}

					{tutorialsToShow.includes('settings') && (
						<TutorialPopup
							id="settings"
							steps={tutorialSteps.settings}
							position={{ 
								top: isSmallDesktop ? 50 : 50, 
								right: isSmallDesktop ? 65 : 55
							}}
							title="Settings"
							openDirection="bottomLeft"
							totalTutorials={tutorialsToShow.length}
							style={{ zIndex: 100010 }}
							onHover={() => handleHover('settings')}
						/>
					)}
				</>
			)}
		</View>
	);
};

const styles = {
	headerBar: {
		flexDirection: "row",
		justifyContent: "space-between",
		alignItems: "center",
		backgroundColor: "transparent",
		paddingTop: isMobile ? 25 : 50,
		paddingHorizontal: isMobile ? 30 : isSmallDesktop ? 40 : 50,
		zIndex: 98,
		width: "100vw",
	},
	logoArea: {
		flex: 1,
		justifyContent: "flex-start",
		maxWidth: isMobile ? "30vw" : "25%",
		marginLeft: isMobile ? -35 : 50,
		display: isMobile ? "none" : "flex"
	},
	loginArea: {
		flexDirection: "row",
		alignItems: "center",
		marginRight: isMobile ? 40 : 75
	},
	loginButton: {
		fontFamily: fontFamilies.Bodoni72Smallcaps,
		paddingHorizontal: 20,
		height: 62,
		justifyContent: "center",
		backgroundColor: "transparent",
		boxShadowOffset: { width: 3, height: 3 },
		boxShadowRadius: 6,
		boxShadowColor: "rgba(0, 0, 0, 0.16)",
	},
	backButton: {
		fontFamily: fontFamilies.Bodoni72Smallcaps,
		height: 62,
		justifyContent: "center",
		backgroundColor: "transparent",
		paddingHorizontal: isMobile ? 0 : isTablet ? 0 : isSmallDesktop ? 5 : 10,
	},
	backButtonText: {
		fontFamily: fontFamilies.Bodoni72Smallcaps,
		fontSize: isMobile ? 20 : isTablet ? 25 : isSmallDesktop ? 28 : 34,
		color: "#e4e4e4",
		textShadowOffset: { width: 3, height: 3 },
		textShadowRadius: 6,
		textShadowColor: "rgba(0, 0, 0, 0.09)",
	},
	loginButtonText: {
		fontFamily: "Orpheus Pro",
		fontSize: isMobile ? 20 : isTablet ? 25 : isSmallDesktop ? 28 : 34,
		lineHeight: 30,
		textAlign: "center",
		color: "#e4e4e4",
		textShadowOffset: { width: 3, height: 3 },
		textShadowRadius: 6,
		textShadowColor: "rgba(0, 0, 0, 0.09)",
	},
	cancelButtonText: {
		fontFamily: "Orpheus Pro",
		fontSize: isMobile ? 20 : isTablet ? 25 : isSmallDesktop ? 28 : 34,
		textAlign: "center",
		color: "#e4e4e4",
		textShadowOffset: { width: 3, height: 3 },
		textShadowRadius: 6,
		textShadowColor: "rgba(0, 0, 0, 0.09)",
		marginHorizontal: 25,
		paddingRight: 90
	},
	signOutButtonText: {
		fontFamily: "Orpheus Pro",
		fontSize: isMobile ? 20 : isTablet ? 25 : isSmallDesktop ? 28 : 34,
		textAlign: "center",
		color: "red",
		textShadowOffset: { width: 3, height: 3 },
		textShadowRadius: 6,
		textShadowColor: "rgba(0, 0, 0, 0.09)",
	},
	logoText: {
		fontFamily: fontFamilies.Bodoni72Smallcaps,
		fontWeight: "normal",
		fontSize: isMobile ? 18 : isTablet ? 50 : isSmallDesktop ? 50 : 55,
		textAlign: "left",
		color: "#fefde2",
		textShadowOffset: { width: 3, height: 3 },
		textShadowRadius: 6,
		textShadowColor: "rgba(0, 0, 0, 0.09)",
	},
	blurredContainer: {
		position: 'absolute',
		top: 0,
		left: 0,
		right: 0,
		bottom: 0,
		backgroundColor: 'rgba(0, 0, 0, 0.5)',
		justifyContent: 'center',
		alignItems: 'center',
		zIndex: 1000,
	},
	overlayBlur: {
		position: "absolute",
		top: 0,
		left: 0,
		right: 0,
		bottom: 0,
		backdropFilter: "blur(25px)", // Adjust the px value to control the amount of blur
		zIndex: 1,
	},
	centeredView: {
		flex: 1,
		justifyContent: 'center',
		alignItems: 'center',
		zIndex: 2, // Ensure it appears above the blur overlay
	},
	modalContainer: {
		width: '80%', // Make the container bigger
		padding: 20,
		backgroundColor: "#fefde2",
		borderRadius: 10,
		alignItems: "center",
		shadowColor: "#000",
		shadowOffset: { width: 0, height: 2 },
		shadowOpacity: 0.25,
		shadowRadius: 4,
		elevation: 5,
	},
	modalContent: {
		alignItems: 'center',
	},
	modalTitle: {
		fontSize: 24,
		fontWeight: 'bold',
		marginBottom: 20,
		fontFamily: fontFamilies.Bodoni72Smallcaps,
	},
	modalButtonContainer: {
		width: '100%',
	},
	modalButton: {
		backgroundColor: "#4c956c",
		padding: 15,
		borderRadius: 5,
		marginTop: 10,
		width: "100%",
		alignItems: "center",
	},
	cancelButton: {
		backgroundColor: "red",
	},
	modalButtonText: {
		fontSize: 16,
		color: "white",
		fontFamily: fontFamilies.Bodoni72Smallcaps,
	},
	settingItem: {
		flexDirection: "row",
		justifyContent: "space-between",
		alignItems: "center",
		marginBottom: 20,
	},
	settingText: {
		fontSize: 16,
		fontFamily: fontFamilies.Bodoni72Smallcaps,
		paddingRight: 10,
	},
	settingDescription: {
		fontSize: 14,
		color: "#333",
		marginTop: 10,
		textAlign: "center",
		fontFamily: fontFamilies.Bodoni72Smallcaps,
	},
	closeModalButton: {
		backgroundColor: "red",
		padding: 10,
		borderRadius: 5,
		marginTop: 10,
		width: "100%",
		alignItems: "center",
	},
	warningText: {
		fontSize: 14,
		marginBottom: 20,
		fontFamily: fontFamilies.Bodoni72Smallcaps,
	},
	icon: {
		width: isMobile ? 20 : isSmallDesktop ? 35 : 40,
		height: isMobile ? 20 : isSmallDesktop ? 35 : 40,
		position: 'absolute',
		top: isMobile ? -10 : isSmallDesktop ? -16 : -20,
		right: isMobile ? -80 : isSmallDesktop ? 20 : 15,
		zIndex: 10001,
	},
};

export default HeaderBar;
